import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IEmployeePosition, IEmployeePositionCreate, IEmployeePositionSearch } from '../interfaces/employeePositions';

export class EmployeePostionsService {
    private readonly ENDPOINT = 'employees-on-positions'

    constructor(private api: ApiClient){}

    public async search(search?: IEmployeePositionSearch){
        return this.api.get<SearchResult<IEmployeePosition>>(this.ENDPOINT, search);
    }

    public async create(payload: IEmployeePositionCreate){
        return this.api.post<IEmployeePosition>(this.ENDPOINT, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>(this.ENDPOINT + '/' + id);
    }
}

export default new EmployeePostionsService(apiClient);