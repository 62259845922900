import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IGroupLookUp } from '../../API/interfaces/groups.interfaces';
import employeesService from '../../API/services/employees.service';
import { IEmployeeLookUp } from '../../API/interfaces/employees.interfaces';
import EmployeeGroupsNew from './EmployeeGroupsNew';
import groupsService from '../../API/services/groups.service';
import employeeGroupsService from '../../API/services/employeeGroups.service';
import EmployeeGroupsItem from './EmployeeGroupsItem';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';
import { IEmployeeGroup, IEmployeeGroupCreate } from '../../API/interfaces/employeeGroups.interfaces';

const EmployeeGroups = () => {
    const { employeeId } = useParams();
    const [groups, setGroups] = useState<IGroupLookUp[]>([]);
    const [employee, setEmployee] = useState<IEmployeeLookUp>();
    const [employeeGroups, setEmployeeGroups] = useState<IEmployeeGroup[]>();
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    const canRead = hasPermission(GroupPermissionEnum.EmployeeRead) && hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead);
    const canEdit = hasPermission(GroupPermissionEnum.EmployeesOnGroupsEdit);

    if(!canRead){
        return <Navigate to="/" replace />
    }

    const searchEmployeeGroups = async () => {
        return employeeGroupsService.search({ employeeId })
            .then((res) => res.items)
            .then(setEmployeeGroups);
    }

    const createEmployeeGroup = async (payload: IEmployeeGroupCreate) => {
        return employeeGroupsService.create(payload)
            .then(searchEmployeeGroups)
            .then(() => setShowForm(false));
    }

    const deleteEmployeeGroup = async (payload: IEmployeeGroup) => {
        return employeeGroupsService.delete(payload.id).then(searchEmployeeGroups);
    }

    useEffect(() => {
        employeesService.lookUp({ id: employeeId })
            .then((res) => res.items[0])
            .then(setEmployee)
            .then(searchEmployeeGroups)
            .catch(console.log);
    }, [employeeId])

    useEffect(() => { 
        groupsService.lookUp()
            .then((res) => setGroups(res.items))
            .catch((err) => console.log(err));
     }, [])

    if (!employee || !employeeGroups) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600 }} />
        </Container>
    )
    
    const assignedGroups = employeeGroups.map((item) => item.groupId);
    const availableGroups = groups.filter((item) => !assignedGroups.includes(item.id));

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/employees"><Typography fontSize={18}>Сотрудники</Typography></Link>
                <Typography color="black" fontSize={18}>Группы сотрудника &quot;{employee.fullName}&quot;</Typography>
            </Breadcrumbs>
            
            {
                canEdit && availableGroups.length > 0 && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить группу'}
                        </Button>
                        {
                            showForm && <EmployeeGroupsNew employee={employee} groups={availableGroups} createEmployeeGroup={createEmployeeGroup} />
                        }
                    </Box>
                )
            }

            {
                employeeGroups.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Сотрудник</TableCell>
                                    {
                                        canEdit && <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                    }
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employeeGroups.map((item) => (
                                        <EmployeeGroupsItem 
                                            employeeGroup={item}
                                            groups={groups}
                                            canEdit={canEdit} 
                                            deleteEmployeeGroup={deleteEmployeeGroup} 
                                            key={item.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>   
                ) 
            }
            
        </Container>
    )
}

export default EmployeeGroups;