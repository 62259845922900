
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IEmployeeGroupCreate } from '../../API/interfaces/employeeGroups.interfaces';
import { IEmployee } from '../../API/interfaces/employees.interfaces';
import { IGroup } from '../../API/interfaces/groups.interfaces';

interface IProps {
    group: IGroup;
    availableEmployees: IEmployee[];
    createGroupEmployee: (payload: IEmployeeGroupCreate) => Promise<void>;
}

const GroupEmployeesNew: FC<IProps> = ({ group, availableEmployees, createGroupEmployee }) => {
    const [employee, setEmployee] = useState<IEmployee | null>(null);

    const handleCreateGroupEmployee = () => employee && createGroupEmployee({ groupId: group.id, employeeId: employee.id });

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Autocomplete sx={{ width: 300 }}
                options={availableEmployees}
                getOptionLabel={(option) => option.lastName + ' ' + option.firstName}
                onChange={(e, value) => setEmployee(value)}
                renderInput={(params) => <TextField {...params} label="Сотрудник" />}
                disablePortal
            />
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreateGroupEmployee}
                disabled={!employee}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default GroupEmployeesNew;