import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Breadcrumbs, Button, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import useSearchState from '../../hooks/useSearchState';
import SearchEmployee from '../SearchEmployee';
import SearchObject from '../SearchObject';
import SearchDate from '../SearchDate';
import { Link, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateFormat, LASTWEEK, TODAY } from '../../utils/dateFormat';
import { ITracker } from '../../API/interfaces/tracker.interfaces';
import contractsService from '../../API/services/contracts.service';
import { IContract } from '../../API/interfaces/contracts.interfaces';
import { ServiceMeasureEnumAbbr } from '../../API/interfaces/services.interfaces';
import trackerService from '../../API/services/tracker.service';
import EmployeePaymentsData from '../../tables/EmployeePaymentsData';
import generateCSVLink from '../../utils/generateCSVLink';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const border = '1px solid rgba(224, 224, 224, 1)';
const backgroundColor = '#fff';

const EmployeePayments: FC = () => {
    const [employeeId, setEmployeeId] = useSearchState('employee');
    const [objectId, setObjectId] = useSearchState('object');
    const [contractId, setContractId] = useSearchState('contract');
    const [dateFrom, setDateFrom] = useSearchState('from', LASTWEEK);
    const [dateTo, setDateTo] = useSearchState('to', TODAY);

    const [trackers, setTrackers] = useState<ITracker[]>([]);
    const [contracts, setContracts] = useState<IContract[]>([]);

    const [isDatesHidden, setDatesHidden] = useState<boolean>(true);

    const hasPermission = usePermissions();

    if (!hasPermission(GroupPermissionEnum.AnalyticsSalary)) {
        return <Navigate to="/" replace />
    }

    const dates = useMemo(() => {
        const dates = [];
        let date = dayjs(dateFrom);
        while (date.isBefore(dayjs(dateTo)) || date.isSame(dayjs(dateTo))) {
            dates.push(date);
            date = date.add(1, 'day');
        }
        return dates;
    }, [dateFrom, dateTo])

    const trackerContracts = useMemo(() => contracts
        .map((contract) => ({ ...contract, services: contract.services.filter(({ service }) => trackers.find((tracker) => tracker.amount && tracker.contractId === contract.id && tracker.serviceId === service.id)) }))
        .filter((contract) => contract.services.length)
        , [contracts, trackers]);

    const filteredContracts = useMemo(() => contractId ? trackerContracts
        .filter((contract) => contract.id == contractId) : trackerContracts
        , [trackerContracts, contractId]);

    const table = useMemo(() => new EmployeePaymentsData(filteredContracts, trackers), [filteredContracts, trackers]);

    useEffect(() => {
        contractsService.searchContracts({ objectId })
            .then((res) => res.items)
            .then(setContracts)
            .catch(console.log);
    }, [employeeId, objectId])

    useEffect(() => {
        trackerService.searchTrackers({
            employeeId,
            objectId,
            dateFrom: dayjs(dateFrom).format(dateFormat),
            dateTo: dayjs(dateTo).format(dateFormat),
        })
            .then((res) => res.items)
            .then(setTrackers)
            .catch(console.log);
    }, [employeeId, objectId, dateFrom, dateTo])

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Зарплата сотрудникам</Typography>
            </Breadcrumbs>

            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchEmployee employeeId={employeeId} setEmployeeId={setEmployeeId} />
                <SearchObject objectId={objectId} setObjectId={setObjectId} />
                {
                    employeeId && objectId && trackerContracts.length > 0 && (
                        <Autocomplete sx={{ width: 300 }}
                            options={trackerContracts}
                            getOptionLabel={(value) => value.name}
                            value={trackerContracts.find((item) => item.id == contractId) || null}
                            onChange={(e, value) => setContractId(value?.id || '')}
                            renderInput={(params) => <TextField {...params} label="Подразделение" />}
                            disablePortal
                        />
                    )
                }
                <SearchDate date={dateFrom} setDate={setDateFrom} />
                <SearchDate date={dateTo} setDate={setDateTo} />
            </Stack>
            {
                employeeId && objectId && filteredContracts.length > 0 && (
                    <Fragment>
                        <TableContainer component={Paper} sx={{ marginTop: '40px', maxHeight: '90vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2} sx={{ minWidth: '180px', border, backgroundColor, position: 'sticky', left: 0, zIndex: 1000 }}>
                                            <Typography fontSize={16}>Название услуги</Typography>
                                        </TableCell>
                                        <TableCell rowSpan={2} sx={{ border, backgroundColor }} align="center">
                                            <Typography fontSize={16}>Цена за единицу</Typography>
                                        </TableCell>
                                        {
                                            Object.entries(dates.reduce((months, date) => {
                                                const month = date.format('MMMM YYYY')
                                                months[month] = months[month] + 1 || 1;
                                                return months;
                                            }, {} as Record<string, number>)).map(([month, count]) => (
                                                <TableCell sx={{ border, backgroundColor, display: isDatesHidden? 'none':'table-cell' }}
                                                    key={month}
                                                    align='center'
                                                    colSpan={count}
                                                >
                                                    <Typography fontSize={16}>{month}</Typography>
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell rowSpan={2} sx={{ border, backgroundColor }} align="center">
                                            <Typography fontSize={16}>Итого в ед. изм.</Typography>
                                        </TableCell>
                                        <TableCell rowSpan={2} sx={{ border, backgroundColor, position: 'sticky', right: 0, zIndex: 1000 }} align="center">
                                            <Typography fontSize={16}>Итого сумма в руб.</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {
                                            dates.map((date) => (
                                                <TableCell
                                                    align='center'
                                                    sx={{ minWidth: '60px', border: '1px solid rgba(0,0,0, .2)', padding: '10px 5px', top: 61,
                                                          display: isDatesHidden? 'none':'table-cell'
                                                    }}
                                                    key={date.format(dateFormat)}
                                                >
                                                    <Typography fontSize={14}>{date.format('DD dd')}</Typography>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        filteredContracts.map((contract) => (
                                            <Fragment key={contract.id}>
                                                <TableRow>
                                                    <TableCell colSpan={isDatesHidden ? 4 : dates.length + 4} align="center" sx={{ border, backgroundColor }}>
                                                        <Typography fontSize={16}>{contract.name}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    contract.services.map(({ service, contractId, serviceId, costForEmployee }) => {
                                                        const totalServicePayments = table.getTotalServicePayments(contractId, serviceId);
                                                        return (
                                                            <TableRow key={service.id} hover={true}>
                                                                <TableCell sx={{ border, backgroundColor, position: 'sticky', left: 0, zIndex: 999 }}>{service.name}, {ServiceMeasureEnumAbbr[service.measure]}</TableCell>
                                                                <TableCell sx={{ border }} align="center">{costForEmployee}</TableCell>
                                                                {
                                                                    dates.map((date) => {
                                                                        const data = table.getServicePaymentsPerDate(contractId, serviceId, date);
                                                                        return (
                                                                            <TableCell key={date.format(dateFormat)} 
                                                                            sx={{ width: '50px', border: '1px solid rgba(0,0,0, .2)',
                                                                                  display: isDatesHidden? 'none':'table-cell'
                                                                            }} 
                                                                            align="center">
                                                                                {Boolean(data.amount) && data.amount}
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                                <TableCell sx={{ border }} align="center">
                                                                    <Typography fontSize={16}>{Boolean(totalServicePayments.amount) && totalServicePayments.amount}</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ border, backgroundColor, position: 'sticky', right: 0, zIndex: 999 }} align="center">
                                                                    <Typography fontSize={16}>{Boolean(totalServicePayments.amount) && totalServicePayments.price}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        ))
                                    }
                                    <TableRow>
                                        <TableCell sx={{ border, backgroundColor, position: 'sticky', left: 0, bottom: 0, zIndex: 999 }}>
                                            <Typography fontSize={16}>Итого в ед.изм</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border }}/>
                                        {
                                            dates.map((date) => {
                                                const data = table.getTotalDatePayments(date);
                                                return (
                                                    <TableCell
                                                        key={date.format(dateFormat)} 
                                                        sx={{ border, backgroundColor, position: 'sticky', bottom: 0, zIndex: 998,
                                                              display: isDatesHidden? 'none':'table-cell'
                                                        }} align="center">
                                                        <Typography fontSize={16}>{data.amount || ''}</Typography>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        <TableCell sx={{ border, backgroundColor, position: 'sticky', right: 0, bottom: 0, zIndex: 999 }} align="center">
                                            <Typography fontSize={18}>{table.getTotalAmounts()}</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border, backgroundColor, position: 'sticky', right: 0, bottom: 0, zIndex: 1000 }} align="center">
                                            <Typography fontSize={18}>{table.getTotalPayments()}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography fontSize={20}>Всего за период {table.getTotalPayments()} ₽</Typography>
                            <div>
                                <Button
                                    variant={isDatesHidden ? 'contained' : 'outlined'}
                                    onClick={() => setDatesHidden(!isDatesHidden)}
                                    sx={{ marginRight: '20px' }}>
                                    {isDatesHidden ? 'Показать' : 'Скрыть'} детали
                                </Button>
                                <a href={generateCSVLink(table.getCSVTable(dates))} download="EmployeePayments.csv">
                                    <Button variant="contained">Скачать</Button>
                                </a>
                            </div>
                        </Box>
                    </Fragment>
                )
            }
        </Container>
    )
}

export default EmployeePayments;