import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { EmployeeStatusEnum } from '../../API/enums/employeeStatus';
import { IEmployeeCreate, EmployeePaymentTypeEnum, EmployeeEmploymentTypeEnum } from '../../API/interfaces/employees.interfaces';
import { IEmploymentPlace } from '../../API/interfaces/employmentPlaces.interfaces';

interface IProps {
    onCreate: (payload: IEmployeeCreate) => Promise<void>,
    places: IEmploymentPlace[]
}

const EmployeesNew: FC<IProps> = ({ onCreate, places }) => {
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [citizenship, setСitizenship] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [status, setStatus] = useState<EmployeeStatusEnum>(EmployeeStatusEnum.Employee);
    const [canAuth, setAuthAbility] = useState<boolean>(false);
    const [paymentType] = useState<EmployeePaymentTypeEnum>(EmployeePaymentTypeEnum.cash);
    const [employmentType] = useState<EmployeeEmploymentTypeEnum>(EmployeeEmploymentTypeEnum.someType);

    const [placeId, setPlaceId] = useState<string>();

    const [error, setError] = useState('');

    const isValid = firstName && lastName && phoneNumber && status && placeId;
    const handleCreate = () => isValid && onCreate({
        firstName,
        middleName,
        lastName,
        phoneNumber,
        citizenship,
        email,
        status,
        employmentType,
        paymentType,
        employmentPlaceId: placeId as string,
        canAuth
    }).catch((err) => err.message && setError(err.message));

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    required={true}
                    label='Фамилия'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    required={true}
                    label='Имя'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Отчество'
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Гражданство'
                    value={citizenship}
                    onChange={(e) => setСitizenship(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Телефон'
                    required={true}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    label='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <FormControl variant='filled' required sx={{ width: '300px' }} >
                    <InputLabel>Статус</InputLabel>
                    <Select value={status} onChange={e => setStatus(e.target.value as EmployeeStatusEnum)} >
                        {
                            Object.values(EmployeeStatusEnum).map((status) => (
                                <MenuItem value={status} key={status}>
                                    {status}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl variant='filled' required sx={{ width: '300px' }} >
                    <InputLabel>Место трудоустройства</InputLabel>
                    <Select required fullWidth value={placeId}
                        onChange={e => setPlaceId(e.target.value)} >
                        {
                            places && places.map((place) => (
                                <MenuItem value={place.id} key={place.id}>
                                    {place.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

            </Stack>
            <Box sx={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={canAuth} onChange={() => setAuthAbility(!canAuth)} />
                <InputLabel>Может авторизироваться</InputLabel>
            </Box>
            {
                error && <Typography marginTop="20px" color="error">{error}</Typography>
            }
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!isValid}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeesNew;