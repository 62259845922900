import { Container, Breadcrumbs, Typography, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton } from '@mui/material'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';
import { IPayment, IPaymentCreate, IPaymentUpdate } from '../../API/interfaces/payments.interfaces';
import paymentsService from '../../API/services/payments.service';
import usePagination from '../../hooks/usePagination';
import EmployeePaymentItem from './EmployeePaymentItem';
import employeesService from '../../API/services/employees.service';
import EmployeePaymentNew from './EmployeePaymentNew';


const EmployeePayment = () => {
    const hasPermission = usePermissions();
    const { employeeId } = useParams();
    const { limit, offset, setCount, Pagination } = usePagination();
    const [showForm, setShowForm] = useState(false);

    const [payments, setPayments] = useState<IPayment[]>();
    const [employeeName, setEmployeeName] = useState<string>('');

    if (!hasPermission(GroupPermissionEnum.PaymentRead)) {
        return <Navigate to="/" replace />
    }

    const searchPayments = async () => {
        return paymentsService.search({ limit, offset, employeeId })
            .then(res => {
                setPayments(res.items);
                setCount(res.count);
            })
            .catch(console.log);
    }

    const createPayment = async (payload: IPaymentCreate) => {
        return paymentsService.create(payload)
            .then(searchPayments)
            .then(() => setShowForm(false))
            .catch(console.log);
    }

    const updatePayment = async (id: string, payload: IPaymentUpdate) => {
        return paymentsService.update(id, payload)
            .then(searchPayments)
            .catch(console.log);
    }

    const deletePayment = async (id: string) => {
        return paymentsService.delete(id)
            .then(searchPayments)
            .catch(console.log);
    }

    const searchEmployee = async () => {
        employeesService.searchEmployees({id: employeeId})
        .then(r => setEmployeeName(`${r.items[0].firstName} ${r.items[0].lastName}`))
    }

    useEffect(() => { searchPayments() }, [limit, offset, employeeId])
    useEffect(() => { searchEmployee() }, [employeeId]);

    if (!payments) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >

            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/employees"><Typography fontSize={18}>Сотрудники</Typography></Link>
                <Typography color="black" fontSize={18}>Выплаты сотрудника &quot;{employeeName}&quot;</Typography>
            </Breadcrumbs>

            <Box sx={{ marginTop: '40px' }}>
                <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                    {showForm ? 'Закрыть' : 'Добавить выплату'}
                </Button>
                {showForm && <EmployeePaymentNew onCreate={createPayment} employeeId={Number(employeeId)} />
                }
            </Box>

            {payments?.length > 0 &&
                (<TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ minWidth: 250 }}>Сумма</TableCell>
                                <TableCell align="center" sx={{ minWidth: 250 }}>Дата <br/> (ГГГГ-ММ-ДД)</TableCell>
                                <TableCell align="center" sx={{ minWidth: 250 }}>Комментарий</TableCell>
                                {
                                    hasPermission(GroupPermissionEnum.PaymentEdit) &&
                                    <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                payments && payments.length > 0 &&
                                payments.map((payment) => (
                                    <EmployeePaymentItem
                                        payment={payment}
                                        updatePayment={updatePayment}
                                        deletePayment={deletePayment}
                                        key={payment.id} />
                                ))
                            }
                        </TableBody>
                    </Table>
                    <Pagination />
                </TableContainer>)
            }

        </Container>
    )
}

export default EmployeePayment;