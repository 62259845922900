import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { ISettings } from '../API/interfaces/settings';
import settingsService from '../API/services/settings.service';

interface ISettingsContext extends ISettings {
    loadSettings: () => Promise<void>;
    updateSettings: (s: ISettings) => Promise<void>;
}

export const SettingsContext = createContext({} as ISettingsContext);

export const useSettings = () => useContext(SettingsContext);

const SettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [settings, setSettings] = useState<ISettings>({});

    async function loadSettings(){
        return settingsService.getSettings()
            .then(setSettings)
    }
    async function updateSettings(s: ISettings){
        return settingsService.updateSettings(s)
            .then(setSettings)
    }

    return (
        <SettingsContext.Provider value={{ ...settings, loadSettings, updateSettings }}>
           { children } 
        </SettingsContext.Provider>
    )
}

export default SettingsProvider;




