import { IconButton, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

interface IProps {
    permission: GroupPermissionEnum,
    canEdit: boolean,
    removePermission: (perm: GroupPermissionEnum) => Promise<void>
}

const GroupsPermissonsItem: FC<IProps> = ({ permission, canEdit, removePermission }) => {
    const handleRemovePermission = () => removePermission(permission);

    return (
        <TableRow>
            <TableCell align="center">{permission}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={handleRemovePermission}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default GroupsPermissonsItem;