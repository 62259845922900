
import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IServiceLookUp } from '../../API/interfaces/services.interfaces';
import { IPosition } from '../../API/interfaces/positions.interfaces';
import { IPositionServiceCreate } from '../../API/interfaces/positionServices.interfaces';

interface IProps {
    position: IPosition,
    services: IServiceLookUp[],
    types: string[],
    createPositionService: (payload: IPositionServiceCreate) => Promise<void>
}

const PositionServicesNew: FC<IProps> = ({ createPositionService, position, services, types }) => {
    const [type, setType] = useState<string | null>(null);

    const typeServices =  services.filter(service => !type  || service.type === type);

    const [service, setService] = useState<IServiceLookUp | null>(null);
    
    const handleCreate = () => service && createPositionService({ positionId: position.id, serviceId: service.id })

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <Autocomplete sx={{ width: 300 }}
                    options={types}
                    onChange={(e, value) => setType(value)}
                    renderInput={(params) => <TextField {...params} label="Тип" />}
                    disablePortal
                />
                <Autocomplete sx={{ width: 300 }}
                    options={typeServices}
                    getOptionLabel={(value) => value.name}
                    onChange={(e, value) => setService(value)}
                    renderInput={(params) => <TextField {...params} label="Услуга" />}
                    disablePortal
                />
            </Stack>
            <Button sx={{ marginTop: '20px' }} 
                variant="outlined" 
                size="large" 
                onClick={handleCreate}
                disabled={!service}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default PositionServicesNew;