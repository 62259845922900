import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Collapse, Drawer, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import cleaningIcon from '../assets/images/cleaning-icon.png';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';

import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';

//
import useToggle from '../hooks/useToggle';
import { FC, useState } from 'react';
import usePermissions from '../hooks/usePermissions';
import { GroupPermissionEnum } from '../API/enums/groupPermission';
import { useAuth } from '../context/Auth';
import { useProfile } from '../context/Profile';

interface IProps {
    isOpen: boolean,
    toggleIsOpen: () => void
}

const version = process.env.REACT_APP_VERSION;
console.log('version:', version);

const Sidebar: FC<IProps> = ({ isOpen, toggleIsOpen }) => {
    const hasPermission = usePermissions();
    const { isAuth, signOut } = useAuth();
    const { profile } = useProfile();
    const [isAnalyticsOpen, toggleAnalyticsOpen] = useToggle();

    const [isScreenHeightShort, setScreenHeightShort] = useState(false);
    const check = () => {
        const height = window.innerHeight;
        if (height < 770 && !isAnalyticsOpen) {
            setScreenHeightShort(true);
        }
        if (height < 770 && isAnalyticsOpen) {
            setScreenHeightShort(false);
        }
    }

    return (
        <Drawer variant='permanent' open={isOpen} sx={{
            '& .MuiDrawer-paper': {
                width: isOpen ? 300 : 74,
                boxSizing: 'border-box',
                overflow: 'hidden',
                padding: '5px',
                transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
            }
        }}>
            <Link to="/">
                <Stack direction="row" alignItems="center" gap="10px" width={290} >
                    <IconButton>
                        <img src={cleaningIcon} width={48} alt="EMS cleaning" />
                    </IconButton>
                    <Typography color='black' fontSize={22} >EMS cleaning</Typography>
                </Stack>
            </Link>
            <List sx={{ width: 290 }}>
                {
                    hasPermission(GroupPermissionEnum.ServiceTrackerRead) && (
                        <Link to='/timesheet'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <EventNoteOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Табель</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    (
                        hasPermission(GroupPermissionEnum.AnalyticsSalary)
                        ||
                        hasPermission(GroupPermissionEnum.AnalyticsCounterpartyCost)
                    ) && (
                        <>
                            <ListItemButton onClick={() => { toggleAnalyticsOpen(); check() }}>
                                <ListItemIcon>
                                    <AssessmentOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary='Аналитика и отчеты' />
                                {isOpen && isAnalyticsOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={isOpen && isAnalyticsOpen} timeout="auto" unmountOnExit>
                                <List disablePadding>
                                    {
                                        hasPermission(GroupPermissionEnum.AnalyticsSalary) && (
                                            <>
                                                <Link to='/analytics/employee-payments'>
                                                    <ListItemButton>
                                                        <ListItemIcon />
                                                        <ListItemText primary="Зарплата сотрудникам" />
                                                    </ListItemButton>
                                                </Link>
                                                <Link to='/analytics/employment-place-payments'>
                                                    <ListItemButton>
                                                        <ListItemIcon />
                                                        <ListItemText primary="Отчет по месту трудоустройства" />
                                                    </ListItemButton>
                                                </Link>
                                            </>
                                        )
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.AnalyticsCounterpartyCost) && (
                                            <Link to='/analytics/counterparty-payments'>
                                                <ListItemButton>
                                                    <ListItemIcon />
                                                    <ListItemText primary="Расчет для заказчика" />
                                                </ListItemButton>
                                            </Link>
                                        )
                                    }
                                </List>
                            </Collapse>
                        </>
                    )
                }
                {
                    hasPermission(GroupPermissionEnum.EmployeeRead) && (
                        <Link to='/employees'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <PeopleAltOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Сотрудники</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    hasPermission(GroupPermissionEnum.PositionRead) && (
                        <Link to='/positions'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <WorkOutlineOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Должности</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    hasPermission(GroupPermissionEnum.GroupRead) && (
                        <Link to='/groups'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <GroupsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Группы</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    hasPermission(GroupPermissionEnum.ObjectRead) && (
                        <Link to='/objects'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <MapsHomeWorkOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Объекты</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    hasPermission(GroupPermissionEnum.ServiceRead) && (
                        <Link to='/services'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <ShoppingCartOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Услуги</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    hasPermission(GroupPermissionEnum.ServiceTypeRead) && (
                        <Link to='/service-types'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddShoppingCartOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Типы услуг</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    hasPermission(GroupPermissionEnum.EmploymentPlaceRead) && 
                    (
                        <Link to='/employment-places'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddHomeWorkOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Места трудоустройства</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }
                {
                    profile?.isSuperUser && (
                        <Link to='/settings'>
                            <ListItemButton>
                                <ListItemIcon>
                                    <SettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>Настройки</ListItemText>
                            </ListItemButton>
                        </Link>
                    )
                }


            </List>
            {version && isOpen &&
                <p style={{ fontFamily: 'Roboto', fontSize: '10px', textAlign: 'center', opacity: .3, position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    {version}
                </p>
            }
            {
                isAuth && profile && isOpen && (
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            transition: 'all 0.25s ease-in-out',
                            opacity: isScreenHeightShort ? 0 : 1,
                            visibility: isScreenHeightShort ? 'hidden' : 'visible',
                            alignItems: 'center',
                            gap: '10px',
                            left: '20px',
                            right: '100px',
                            bottom: '16px',
                        }}
                    >
                        <IconButton size='medium' onClick={signOut}><LogoutIcon /></IconButton>
                        <Typography sx={{ flexGrow: '1', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{profile?.lastName} {profile?.firstName}</Typography>
                    </Box>
                )
            }

            <IconButton onClick={() => toggleIsOpen()} size='large' sx={{
                //margin: 'auto 0 0 auto', 
                position: 'absolute', bottom: '2px', right: isOpen? '-5px' : '5px',
                width: 64, height: 64
            }}>
                {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </Drawer>
    );
}


export default Sidebar;