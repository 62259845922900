import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { IPositionCreate } from '../../API/interfaces/positions.interfaces';

interface IProps {
    createPosition: (payload: IPositionCreate) => Promise<void>
}

const PositionsNew: FC<IProps> = ({ createPosition }) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    const handleCreate = () => {
        setError('');
        if(name) createPosition({ name })
            .catch((err) => err.message && setError(err.message));
    }

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField type="text"
                    sx={{ width: 300 }}
                    variant="filled"
                    required={true}
                    label='Название'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Stack>
            {
                error && <Typography marginTop="20px" color="error">{error}</Typography>
            }
            
            <Button sx={{ marginTop: '20px' }} 
                variant="outlined" 
                size="large" 
                onClick={handleCreate}
                disabled={!name}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default PositionsNew;