import { FC, useState } from 'react';
import { IEmploymentPlaceCreate } from '../../API/interfaces/employmentPlaces.interfaces';
import { Box, Stack, TextField, Button } from '@mui/material';

interface IProps {
    onCreate: (payload: IEmploymentPlaceCreate) => void
}

const EmploymentPlacesNew: FC<IProps> = ({onCreate}) => {
    const [name, setName] = useState('');

    const handleCreate = () => onCreate({ name })

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                    type="text"
                    variant="filled"
                    required={true}
                    label='Название'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Stack>
            <Button sx={{ marginTop: '20px' }} 
                variant="outlined" 
                size="large" 
                onClick={handleCreate}
                disabled={!name}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmploymentPlacesNew;