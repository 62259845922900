import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import EmployeesNew from './EmployeesNew';
import EmployeesItem from './EmployeesItem';
import { IEmployeeCreate, IEmployee, IEmployeeUpdate } from '../../API/interfaces/employees.interfaces';
import employeesService from '../../API/services/employees.service';
import SearchObject from '../SearchObject';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePagination from '../../hooks/usePagination';
import { IEmploymentPlace } from '../../API/interfaces/employmentPlaces.interfaces';
import employmentPlacesService from '../../API/services/employmentPlaces.service';
import SearchContract from '../SearchContract';

const Employees: FC = () => {
    const hasPermission = usePermissions();
    const { limit, offset, setCount, Pagination } = usePagination();
    const [objectId, setObjectId] = useState<string>('');
    const [contractId, setContractId] = useState<string>('');

    const [employees, setEmployees] = useState<IEmployee[]>();
    const [showForm, setShowForm] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const [places, setPlaces] = useState<IEmploymentPlace[]>([]);

    if (!hasPermission(GroupPermissionEnum.EmployeeRead)) {
        return <Navigate to="/" replace />
    }

    const searchEmployees = async () => {
        return employeesService.searchEmployees({ limit, offset, objectId, contractId })
            .then(res => {
                setEmployees(res.items);
                setCount(res.count);
            })
            .catch(console.log);
    }

    const createEmployee = async (payload: IEmployeeCreate) => {
        return employeesService.createEmployee(payload)
            .then(searchEmployees)
            .then(() => setShowForm(false))
            .catch(console.log);
    }

    const updateEmployee = async (id: string, payload: IEmployeeUpdate) => {
        return employeesService.updateEmployee(id, payload)
            .then(searchEmployees)
            .catch(console.log);
    }

    const deleteEmployee = async (id: string) => {
        return employeesService.deleteEmployee(id)
            .then(searchEmployees)
            .catch(console.log);
    }

    useEffect(() => { searchEmployees() }, [limit, offset, objectId, contractId]);

    useEffect(() => {
        employmentPlacesService.search({})
        .then(r => setPlaces(r.items))
        .catch(console.log)
    }, [])

    if (!employees) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600 }} />
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Сотрудники</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.EmployeeEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить сотрудника'}
                        </Button> <br />
                        {showForm && <EmployeesNew onCreate={createEmployee} places={places} />}
                    </Box>
                )
            }

            <Box sx={{ marginTop: '40px' }}>
                <Button variant="outlined" size="large" sx={{ marginTop: '10px' }} onClick={() => setShowFilter(v => !v)}>
                    {showFilter ? 'Закрыть' : 'Добавить фильтр'}
                </Button>

                {
                    showFilter && (
                        <Stack direction="row" flexWrap="wrap" gap="10px" sx={{ marginTop: '20px' }}>
                            <SearchObject objectId={objectId} setObjectId={setObjectId} />
                            <SearchContract objectId={objectId} contractId={contractId} setContractId={setContractId} />
                        </Stack>

                    )
                }
            </Box>

            {
                employees.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', height: '90vh', padding: '0 0 20px 0', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Фамилия</TableCell>
                                    <TableCell align="center">Имя</TableCell>
                                    <TableCell align="center">Отчество</TableCell>
                                    <TableCell align="center">Телефон</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Гражданство</TableCell>
                                    <TableCell align="center">Статус</TableCell>
                                    <TableCell align="center">Место <br />трудоустройства</TableCell>
                                    <TableCell align="center">Может<br />авторизироваться</TableCell>
                                    <TableCell align="center" width="200px">Подразделения</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeesOnPositionsRead) && <TableCell align="center" width="120px">Должности</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && <TableCell align="center" width="120px">Группы</TableCell>
                                    }
                                    {
                                        // TEMP hasPermission(GroupPermissionEnum.PositionsOnServicesRead) && <TableCell align="center" width="120px">Услуги</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.DocumentRead) && <TableCell align="center" width="120px">Документы</TableCell>
                                    }
                                    <TableCell align="center" width="120px">Выплаты</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeeEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employees.map(employee => (
                                        <EmployeesItem
                                            employee={employee}
                                            updateEmployee={updateEmployee}
                                            deleteEmployee={deleteEmployee}
                                            key={employee.id}
                                            places={places}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <Pagination />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default Employees;