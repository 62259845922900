import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Breadcrumbs, Button, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import SearchObject from '../SearchObject';
import SearchDate from '../SearchDate';
import useSearchState from '../../hooks/useSearchState';
import { Link, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateFormat, LASTWEEK, TODAY } from '../../utils/dateFormat';
import { IContract } from '../../API/interfaces/contracts.interfaces';
import { ITracker } from '../../API/interfaces/tracker.interfaces';
import contractsService from '../../API/services/contracts.service';
import trackerService from '../../API/services/tracker.service';
import CounterpartyPaymentsTable from '../../tables/CounterpartyPaymentsTable';
import { ServiceMeasureEnumAbbr } from '../../API/interfaces/services.interfaces';
import generateCSVLink from '../../utils/generateCSVLink';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const border = '1px solid rgba(224, 224, 224, 1)';
const backgroundColor = '#fff';

const CounterpartyPayments: FC = () => {
    const [objectId, setObjectId] = useSearchState('object');
    const [contractId, setContractId] = useSearchState('contract');
    const [dateFrom, setDateFrom] = useSearchState('from', LASTWEEK);
    const [dateTo, setDateTo] = useSearchState('to', TODAY);

    const [contracts, setContracts] = useState<IContract[]>([]);
    const [trackers, setTrackers] = useState<ITracker[]>([]);

    const [isDatesHidden, setDatesHidden] = useState<boolean>(true);

    const hasPermission = usePermissions();
    if (!hasPermission(GroupPermissionEnum.AnalyticsCounterpartyCost)) {
        return <Navigate to="/" replace />
    }

    useEffect(() => {
        contractsService.searchContracts({ objectId })
            .then(res => res.items)
            .then(setContracts)
            .catch(console.log);
    }, [objectId])

    useEffect(() => {
        trackerService.searchTrackers({
            objectId,
            dateFrom: dayjs(dateFrom).format(dateFormat),
            dateTo: dayjs(dateTo).format(dateFormat),
        })
            .then(res => res.items)
            .then(setTrackers)
            .catch(console.log);
    }, [objectId, dateFrom, dateTo])

    const dates = useMemo(() => {
        const dates = [];
        let date = dayjs(dateFrom);
        while (date.isBefore(dayjs(dateTo)) || date.isSame(dayjs(dateTo))) {
            dates.push(date);
            date = date.add(1, 'day');
        }
        return dates;
    }, [dateFrom, dateTo])

    const trackerContracts = useMemo(() => contracts
        .map((contract) => ({ ...contract, services: contract.services.filter(({ service }) => trackers.find((tracker) => tracker.amount && tracker.contractId === contract.id && tracker.serviceId === service.id)) }))
        .filter((contract) => contract.costForCounterpartyFixed || contract.services.length)
        , [contracts, trackers])

    const filteredContracts = useMemo(() => contractId ? trackerContracts
        .filter((contract) => contract.id == contractId) : trackerContracts
        , [trackerContracts, contractId]);

    const table = useMemo(() => new CounterpartyPaymentsTable(filteredContracts, trackers), [filteredContracts, trackers]);

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Расчет для заказчика</Typography>
            </Breadcrumbs>
            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchObject objectId={objectId} setObjectId={setObjectId} />
                {
                    objectId && trackerContracts.length > 0 && (
                        <Autocomplete sx={{ width: 300 }}
                            options={trackerContracts}
                            getOptionLabel={(value) => value.name}
                            value={trackerContracts.find((item) => item.id == contractId) || null}
                            onChange={(e, value) => setContractId(value?.id || '')}
                            renderInput={(params) => <TextField {...params} label="Подразделение" />}
                            disablePortal
                        />
                    )
                }
                <SearchDate date={dateFrom} setDate={setDateFrom} />
                <SearchDate date={dateTo} setDate={setDateTo} />
            </Stack>
            {
                Boolean(contracts.length) && (
                    <Fragment>
                        <TableContainer component={Paper} sx={{ marginTop: '40px', maxHeight: '90vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2} sx={{ minWidth: '180px', border, backgroundColor, position: 'sticky', left: 0, zIndex: 1001 }}>
                                            <Typography fontSize={16}>Название услуги</Typography>
                                        </TableCell>
                                        <TableCell rowSpan={2} sx={{ border }} align="center">
                                            <Typography fontSize={16}>Цена за единицу</Typography>
                                        </TableCell>
                                        {
                                            Object.entries(dates.reduce((months, date) => {
                                                const month = date.format('MMMM YYYY')
                                                months[month] = months[month] + 1 || 1;
                                                return months;
                                            }, {} as Record<string, number>)).map(([month, count]) => (
                                                <TableCell sx={{ border, backgroundColor, display: isDatesHidden? 'none':'table-cell' }}
                                                    key={month}
                                                    align='center'
                                                    colSpan={count}
                                                >
                                                    <Typography fontSize={16}>{month}</Typography>
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell rowSpan={2} sx={{ border, backgroundColor }} align="center">
                                            <Typography fontSize={16}>Итого в ед. изм.</Typography>
                                        </TableCell>
                                        <TableCell rowSpan={2} sx={{ border, backgroundColor, position: 'sticky', right: 0, zIndex: 1001 }} align="center">
                                            <Typography fontSize={16}>Итого сумма в руб.</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {
                                            dates.map((date) => (
                                                <TableCell
                                                    align='center'
                                                    sx={{ minWidth: '60px', border, backgroundColor, padding: '10px 5px', top: 61,display: isDatesHidden? 'none':'table-cell' }}
                                                    key={date.format(dateFormat)}
                                                >
                                                    <Typography fontSize={14}>{date.format('DD dd')}</Typography>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        filteredContracts.map((contract) => (
                                            <React.Fragment key={contract.id}>
                                                <TableRow>
                                                    <TableCell colSpan={isDatesHidden ? 4 : dates.length + 4} align="center" sx={{ border, backgroundColor }}>
                                                        <Typography fontSize={16}>
                                                            {contract.name} {Boolean(contract.costForCounterpartyFixed) && `(Фиксированная цена по договору ${contract.costForCounterpartyFixed} ₽)`}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    contract.services.map(({ service, contractId, serviceId, costForCounterparty }) => {
                                                        const totalServicePayments = table.getTotalServicePayments(contractId, serviceId);
                                                        return (
                                                            <TableRow key={service.id} hover={true}>
                                                                <TableCell sx={{ border, backgroundColor, position: 'sticky', left: 0, zIndex: 1000 }}>{service.name}, {ServiceMeasureEnumAbbr[service.measure]}</TableCell>
                                                                <TableCell sx={{ border }} align="center">{contract.costForCounterpartyFixed ? '—' : costForCounterparty}</TableCell>
                                                                {
                                                                    dates.map((date) => {
                                                                        const data = table.getServicePaymentsPerDate(contractId, serviceId, date);
                                                                        return (
                                                                            <TableCell sx={{ width: '50px', border, display: isDatesHidden? 'none':'table-cell' }} align="center" key={date.toString()}>
                                                                                {Boolean(data.amount) && data.amount}
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                                <TableCell sx={{ border }} align="center">
                                                                    <Typography fontSize={16}>{Boolean(totalServicePayments.amount) && totalServicePayments.amount}</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ border, backgroundColor, position: 'sticky', right: 0, zIndex: 1000 }} align="center">
                                                                    <Typography fontSize={16}>{contract.costForCounterpartyFixed ? '—' : Boolean(totalServicePayments.amount) && totalServicePayments.price}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                        ))
                                    }

                                    <TableRow>
                                        <TableCell sx={{ border, backgroundColor, position: 'sticky', left: 0, bottom: 0, zIndex: 1001 }}>
                                            <Typography fontSize={16}>Итого в ед.изм</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border }}/>
                                        {
                                            dates.map((date) => {
                                                const data = table.getTotalDatePayments(date);
                                                return (
                                                    <TableCell key={date.toString()}
                                                        sx={{
                                                            width: '50px', border, backgroundColor,
                                                            position: 'sticky', bottom: 0, zIndex: 1000,
                                                            display: isDatesHidden? 'none':'table-cell'
                                                        }} align="center">
                                                        <Typography fontSize={18}>{Boolean(data.amount) && data.amount}</Typography>
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        <TableCell sx={{ border, backgroundColor, position: 'sticky', right: 0, bottom: 0, zIndex: 1000 }} align="center">
                                            <Typography fontSize={18}>{table.getTotalPayments().amount}</Typography>
                                        </TableCell>
                                        <TableCell sx={{ border, backgroundColor, position: 'sticky', right: 0, bottom: 0, zIndex: 1001 }} align="center">
                                            <Typography fontSize={18}>{table.getTotalPayments().price}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography fontSize={20}>Всего за период {table.getTotalPayments().price} ₽</Typography>
                            <div>
                                <Button
                                    variant={isDatesHidden ? 'contained' : 'outlined'}
                                    onClick={() => setDatesHidden(!isDatesHidden)}
                                    sx={{ marginRight: '20px' }}>
                                    {isDatesHidden ? 'Показать' : 'Скрыть'} детали
                                </Button>
                                <a href={generateCSVLink(table.getCSVTable(dates))} download="EmployeePayments.csv">
                                    <Button variant="contained">Скачать</Button>
                                </a>
                            </div>
                        </Box>
                    </Fragment>
                )
            }
        </Container>
    )
}

export default CounterpartyPayments;
