import { FC, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { IEmployeeLookUp } from '../API/interfaces/employees.interfaces';
import employeesService from '../API/services/employees.service';

interface ISearchEmployeeProps {
    employeeId: string;
    setEmployeeId: (value: string) => void;
}

const SearchEmployee: FC<ISearchEmployeeProps> = ({ employeeId, setEmployeeId }) => {
    const [employees, setEmployees] = useState<IEmployeeLookUp[]>([]);
    const employee = (employeeId && employees.find((item) => item.id == employeeId)) || null;

    useEffect(() => {
        employeesService.lookUp()
            .then((res) => res.items)
            .then(setEmployees)
            .catch(console.log);
    }, [])

    return (
        <Autocomplete sx={{ width: 300 }}
            options={employees}
            getOptionLabel={(value) => value.fullName}
            value={employee}
            onChange={(e, value) => setEmployeeId(value?.id || '')}
            renderInput={(params) => <TextField {...params} label="Сотрудник" />}
            disablePortal
        />
    )
}

export default SearchEmployee;