import { createTheme, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import useToggle from '../hooks/useToggle';
import Sidebar from './Sidebar';
import { ruRU } from '@mui/material/locale';
import { Outlet } from 'react-router-dom';

const theme = createTheme(
    { },
    ruRU,
);

const Layout: FC = () => {
    const [sidebarIsOpen, toggleSidebarIsOpen] = useToggle(true);

    return (
        <ThemeProvider theme={theme}>
            <Sidebar isOpen={sidebarIsOpen} toggleIsOpen={toggleSidebarIsOpen}/>
            <Box sx={{
                paddingLeft: sidebarIsOpen ? '300px' : '74px',
                transition: 'padding-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms' 
            }}>
                <Outlet/>
            </Box>
        </ThemeProvider>
    )
}

export default Layout;