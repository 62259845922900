import apiClient, { ApiClient } from '../apiClient';
import { IResetPassword, IAuth, IRegister, ISignUp } from '../interfaces/auth.interfaces';

export class AuthService {
    constructor(private api: ApiClient){}

    public async login(credentials: ISignUp){
        return this.api.post<IAuth>('auth/signup', credentials)
            .then(auth => this.api.setToken(auth.accessToken));
    }

    public async refresh(){
        return this.api.post<IAuth>('auth/refresh-token')
            .then(auth => this.api.setToken(auth.accessToken));
    }

    public async register(credentials: IRegister){
        return this.api.post<IAuth>('auth/register', credentials)
            .then(auth => this.api.setToken(auth.accessToken));
    }

    public async changePassword(credentials: IResetPassword){
        return this.api.post<void>('auth/change-password', credentials);
    }
}


export default new AuthService(apiClient);