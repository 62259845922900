import { GroupPermissionEnum } from '../API/enums/groupPermission';
import { useProfile } from '../context/Profile';

export function usePermissionList(permissions: GroupPermissionEnum[]){
    const hasPermission = usePermissions();
    return permissions.map(hasPermission);
}

export default function usePermissions(){
    const { profile } = useProfile();
    if (!profile) return () => false;
    if (profile.isSuperUser) return () => true;
    const profilePermissions = new Set<GroupPermissionEnum>();
    profile.groups.forEach(({ group }) => group?.permissions.forEach(profilePermissions.add.bind(profilePermissions)));
    return profilePermissions.has.bind(profilePermissions);
}
