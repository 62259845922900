import apiClient, { ApiClient } from '../apiClient';
import { GroupedEmployeesPayments, IEmployeeSalary, IEmployeeSalaryFilter } from '../interfaces/analitycs.interfaces';

export class AnalyticsService {
    constructor(private api: ApiClient){}

    async getPositionHours(){
        return this.api.get('analytics/positions-hours', {});         
    }

    async getEmployeesSalary(filter: IEmployeeSalaryFilter){
        return this.api.get<IEmployeeSalary[]>('analytics/employees-salary', filter);   
    }

    async getEmployeesPayments(){
        return this.api.get<GroupedEmployeesPayments[]>('analytics/employee-payments');
    }
}

export default new AnalyticsService(apiClient);