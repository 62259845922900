import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IPositionService, IPositionServiceCreate, IPositionServiceSearch, IPositionServiceUpdate } from '../interfaces/positionServices.interfaces';

export class PositionServicesService {
    constructor(private api: ApiClient){}

    private route = 'positions-on-services';

    public async search(search?: IPositionServiceSearch){
        return this.api.get<SearchResult<IPositionService>>(this.route, search);
    }

    public async create(payload: IPositionServiceCreate){
        return this.api.post<IPositionService>(this.route, payload);
    }

    public async update(id: string, payload: IPositionServiceUpdate){
        return this.api.patch<IPositionService>(`${this.route}/${id}`, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>(`${this.route}/${id}`);
    }
}

export default new PositionServicesService(apiClient);