import { Autocomplete, Box, Button, Stack, TextField} from '@mui/material';
import { FC, useState } from 'react';
import { IContractServiceCreate } from '../../API/interfaces/contractServices.interfaces';
import { IService } from '../../API/interfaces/services.interfaces';
import { IContract } from '../../API/interfaces/contracts.interfaces';

interface IProps {
    contract: IContract,
    availableServices: IService[],
    createContractService: (payload: IContractServiceCreate) => Promise<void>,
}

const ContractServicesNew: FC<IProps> = ({ createContractService, availableServices, contract }) => {
    const [service, setService] = useState<IService | null>(null);
    const [costForCounterparty, setCostForCounterparty] = useState<number>(0);
    const [costForEmployee, setCostForEmployee] = useState<number>(0);

    const handleCreate = () => service && createContractService({ contractId: contract.id, serviceId: service.id, costForCounterparty, costForEmployee });

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <Autocomplete sx={{ width: 300 }}
                    options={availableServices}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => setService(value)}
                    renderInput={(params) => <TextField {...params} label="Услуга" />}
                    disablePortal
                />
                {
                    service && !contract.costForCounterpartyFixed && (
                        <TextField type="number"
                            sx={{ width: '300px' }}
                            variant="filled"
                            fullWidth
                            label="Цена для заказчика"
                            value={costForCounterparty}
                            onChange={(e) => setCostForCounterparty(+e.target.value)}
                        />
                    )
                }
                {
                    service && (
                        <TextField type="number"
                            sx={{ width: '300px' }}
                            variant="filled"
                            fullWidth
                            label="Цена для сотрудников"
                            value={costForEmployee}
                            onChange={e => {setCostForEmployee(+e.target.value)}}
                        />
                    )
                }
            </Stack>
            <Button sx={{ marginTop: '20px' }} variant="outlined" size='large' onClick={handleCreate} disabled={!service}>
                Добавить
            </Button>
        </Box>
    )
}

export default ContractServicesNew;