export enum DocumentTypeEnum {
    passport,
    inn,
    snils,
    untyped,
}

export const DocumentTypelabels: Record<DocumentTypeEnum, string> = {
    [DocumentTypeEnum.untyped]: 'другое',
    [DocumentTypeEnum.passport]: 'Паспорт',
    [DocumentTypeEnum.inn]: 'ИНН',
    [DocumentTypeEnum.snils]: 'СНИЛС',
}