import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Breadcrumbs, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import SearchEmployee from '../SearchEmployee';
import SearchObject from '../SearchObject';
import SearchDate from '../SearchDate';
import useSearchState from '../../hooks/useSearchState';
import { Link, Navigate } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { dateFormat, LASTWEEK, TODAY } from '../../utils/dateFormat';
import dayjs, { Dayjs } from 'dayjs';
import { IContract } from '../../API/interfaces/contracts.interfaces';
import { ITracker } from '../../API/interfaces/tracker.interfaces';
import contractsService from '../../API/services/contracts.service';
import trackerService from '../../API/services/tracker.service';
import { trackersMapGetter, trackersMapReducer } from '../../utils/mapReducers';
import { ServiceMeasureEnumAbbr } from '../../API/interfaces/services.interfaces';
import ServiceTrackersItem from './ServiceTrackersItem';

const border = '1px solid rgba(0,0,0, .2)';
const backgroundColor = '#fff';

const ServiceTrackers: React.FC = () => {
    const [employeeId, setEmployeeId] = useSearchState('employee');
    const [objectId, setObjectId] = useSearchState('object');
    const [contractId, setContractId] = useSearchState('contract');
    const [dateFrom, setDateFrom] = useSearchState('from', LASTWEEK);
    const [dateTo, setDateTo] = useSearchState('to', TODAY);

    const [trackersMap, setTrackersMap] = useState<Map<string, ITracker>>(new Map());
    const [contracts, setContracts] = useState<IContract[]>([]);

    const dates = useMemo(() => {
        const dates = [];
        let date = dayjs(dateFrom);
        while (date.isBefore(dayjs(dateTo)) || date.isSame(dayjs(dateTo))) {
            dates.push(date);
            date = date.add(1, 'day');
        }
        return dates;
    }, [dateFrom, dateTo])

    const hasPermission = usePermissions();

    if(!hasPermission(GroupPermissionEnum.ServiceTrackerRead)){
        return <Navigate to="/" replace />
    }

    useEffect(() => {
        if(employeeId && objectId) {
            contractsService.searchContracts({ objectId, employeeId })
                .then((res) => res.items)
                .then(setContracts)
                .catch(console.log);
            
        }
    }, [objectId, employeeId])

    useEffect(() => {
        if(employeeId && objectId){
            trackerService.searchTrackers({
                employeeId: employeeId,
                objectId: objectId,
                dateFrom: dayjs(dateFrom).format(dateFormat),
                dateTo: dayjs(dateTo).format(dateFormat),
            })
                .then((res) => setTrackersMap(res.items.reduce(trackersMapReducer, new Map())))
                .catch(console.log);
        }
    }, [employeeId, objectId, dateFrom, dateTo])

    const createTracker = (contractId: string, serviceId: string, dateAt: Dayjs, amount: number) => {
        trackerService.createTracker({
            employeeId,
            objectId,
            serviceId,
            contractId,
            amount,
            dateAt: dateAt.format(dateFormat)
        })
            .then(tracker => setTrackersMap((map) => trackersMapReducer(new Map(map), tracker)))
            .catch(console.log);
    }

    const updateTracker = (trackerId: string, amount: number) => {
        trackerService.updateTracker(trackerId, { amount })
            .then(tracker => setTrackersMap((map) => trackersMapReducer(new Map(map), tracker)))
            .catch(console.log);
    }

    const dateTrackersSumm = new Map<string, number>();
    let totalSumm = 0;

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Табель</Typography>
            </Breadcrumbs>

            <Stack direction='row' gap='10px' flexWrap='wrap' marginTop='40px'>
                <SearchEmployee employeeId={employeeId} setEmployeeId={setEmployeeId} />
                <SearchObject objectId={objectId} setObjectId={setObjectId} />
                {
                   employeeId && objectId && contracts.length > 0 && (
                       <Autocomplete sx={{ width: 300 }}
                           options={contracts}
                           getOptionLabel={(value) => value.name}
                           value={contracts.find((item) => item.id == contractId) || null}
                           onChange={(e, value) => setContractId(value?.id || '')}
                           renderInput={(params) => <TextField {...params} label="Подразделение" />}
                           disablePortal
                       />
                   ) 
                }
                <SearchDate date={dateFrom} setDate={setDateFrom}/>
                <SearchDate date={dateTo} setDate={setDateTo}/>
            </Stack>

            {
                employeeId && objectId && contracts.length == 0 && (
                    <Box sx={{ marginTop:'40px'}}>
                        <Typography color="black" fontSize={24}>Сотрудник не оказывал услуг в рамках объекта</Typography>
                    </Box>  
                )
            }
                   
            {
                employeeId && objectId && contracts.length > 0 && (
                    <Box sx={{ marginTop:'40px'}}>
                            <TableContainer component={Paper} sx={{ maxHeight: '90vh' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell rowSpan={2} sx={{ minWidth: '160px', border, backgroundColor, position: 'sticky', left: 0, zIndex: 1000 }}>
                                                <Typography fontSize={18}>Название услуги</Typography>
                                            </TableCell>
                                            {
                                                Object.entries(dates.reduce((months, date) => {
                                                    const monthString = date.format('MMMM YYYY')
                                                    months[monthString] = months[monthString] + 1 || 1;
                                                    return months;
                                                }, {} as Record<string, number>)).map(([monthString, count]) => (
                                                    <TableCell
                                                        colSpan={count}
                                                        align='center'
                                                        sx={{ border, backgroundColor }}
                                                        key={monthString}
                                                    >
                                                        <Typography fontSize={16}>{monthString}</Typography>
                                                    </TableCell>
                                                ))
                                            }
                                            <TableCell rowSpan={2} width="120px" sx={{ border, backgroundColor, position: 'sticky', right: 0, zIndex: 1000 }}>
                                                <Typography fontSize={18}>Итого за период</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {
                                                dates.map((date) => (
                                                    <TableCell
                                                        align='center'
                                                        sx={{ minWidth: '60px', border, backgroundColor, padding: '10px 5px', top: 66}}
                                                        key={date.format(dateFormat)}
                                                    >
                                                        <Typography fontSize={14}>{date.format('DD dd')}</Typography>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            contracts.filter(contract => !contractId || contractId == contract.id).map(contract => (
                                                <React.Fragment key={contract.id}>
                                                    <TableRow>
                                                        <TableCell colSpan={dates.length + 2} align="center" sx={{ border, backgroundColor }}>
                                                            <Typography fontSize={18}>{contract.name}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        !contract.services.length && (
                                                            <TableRow>
                                                                <TableCell colSpan={dates.length + 2} align="center" sx={{ border, backgroundColor }}>
                                                                    <Typography fontSize={16}>Сотрудник не оказывал услуг</Typography>
                                                                </TableCell>
                                                            </TableRow>    
                                                        )
                                                    }

                                                    {
                                                        contract.services.map(({ contractId, serviceId, service }) => {
                                                            let serviceAmount = 0;
                                                            return (
                                                                <TableRow key={service.id} hover={true}>
                                                                    <TableCell sx={{ border, position: 'sticky', left: 0, zIndex: 999, backgroundColor }}>
                                                                        <Typography fontSize={16}>{service.name}, {ServiceMeasureEnumAbbr[service.measure]}</Typography>
                                                                    </TableCell>
                                                                    {
                                                                        dates.map((date) => {
                                                                            const dateAt = date.format(dateFormat);
                                                                            const tracker = trackersMapGetter(trackersMap, { contractId, serviceId, dateAt });
                                                                            if (tracker) {
                                                                                serviceAmount += tracker.amount;
                                                                                let dateSumm = dateTrackersSumm.get(dateAt) || 0;
                                                                                dateSumm += tracker.amount;
                                                                                totalSumm += tracker.amount;
                                                                                dateTrackersSumm.set(dateAt, dateSumm);
                                                                            } 
                                                                            return  <ServiceTrackersItem
                                                                                        tracker={tracker}
                                                                                        onCreate={(amount) => createTracker(contractId, serviceId, date, amount)}
                                                                                        onUpdate={updateTracker} 
                                                                                        key={dateAt}
                                                                                    />
                                                                        })
                                                                    }
                                                                    <TableCell align="center" sx={{ border, position: 'sticky', right: 0, zIndex: 999, backgroundColor }}>
                                                                        <Typography fontSize={16}>{ Boolean(serviceAmount) && serviceAmount }</Typography>  
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                        <TableRow>
                                            <TableCell sx={{ border, position: 'sticky', left: 0, bottom: 0, zIndex: 999, backgroundColor}}>
                                                <Typography fontSize={18}>Итого за день в ед.изм</Typography>  
                                            </TableCell>
                                            { 
                                                dates.map((date) => {
                                                    const dateAt = date.format(dateFormat);
                                                    return (
                                                        <TableCell key={dateAt} sx={{  border, position: 'sticky',  bottom: 0, zIndex: 998, backgroundColor}} align="center">
                                                            <Typography fontSize={18}>{ dateTrackersSumm.get(dateAt) || '' }</Typography>  
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                            <TableCell align="center" sx={{ border, position: 'sticky', right: 0 , bottom: 0, zIndex: 999, backgroundColor}}>
                                                <Typography fontSize={18}>{ totalSumm || '' }</Typography>  
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </Box>
                )
            }
        </Container>
    )
}

export default ServiceTrackers;