import {  IconButton, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IContractEmployee } from '../../API/interfaces/contractEmployees';
import { IEmployeeLookUp } from '../../API/interfaces/employees.interfaces';

interface IProps {
    contractEmployee: IContractEmployee,
    employee?: IEmployeeLookUp,
    canEdit: boolean,
    deleleContractEmployee: (id: string) => Promise<void>,
}

const ContractEmployeesItem: FC<IProps> = ({ contractEmployee, employee, canEdit, deleleContractEmployee }) => {
    const handleDelete = () => deleleContractEmployee(contractEmployee.id);

    return (
        <TableRow>
            <TableCell align="center">{employee?.fullName}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ContractEmployeesItem;