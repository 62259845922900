import { TableCell } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { ITracker } from '../../API/interfaces/tracker.interfaces';
import { useProfile } from '../../context/Profile';
import { useSettings } from '../../context/Settings';

interface IProps {
    tracker?: ITracker;
    onCreate: (amount: number) => void;
    onUpdate: (id: string, amount: number) => void;
}

const ServiceTrackersItem: React.FC<IProps> = ({ tracker, onCreate, onUpdate }) => {
    const [inputValue, setInputValue] = useState<number>(tracker?.amount || 0);
    const { profile } = useProfile();
    const { daysToEdit } = useSettings();
    const canEdit = profile?.isSuperUser || !tracker || dayjs().diff(tracker.createdAt, 'day') < (daysToEdit || 1);

    const save = () => {
        if (tracker) return onUpdate(tracker.id, Number(inputValue));
        return onCreate(Number(inputValue));
    }

    useEffect(() => {
        setInputValue(tracker?.amount || 0)
    }, [tracker])

    const [height, setHeight] = useState(0)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
            if (ref.current) {
                setHeight(ref.current.clientHeight)
            }
      }, []);

    return (
        <TableCell ref={ref} sx={{ width: '50px', height: '100%', border: '1px solid rgba(0,0,0, .2)', backgroundColor: canEdit ? 'none' : 'lightgrey', padding: 0 }}>
            <input
                maxLength={2} type="number" id="inputID" min={0} max={24}
                style={{
                    width: '100%',
                    height: height,
                    border: 'none',
                    background: 'none',
                    textAlign: 'center',
                    fontSize: '16px'
                }}

                value={inputValue || ''}
                onChange={e => canEdit && setInputValue(+e.target.value)}
                onBlur={() => canEdit && save()}
                disabled={!canEdit}
            />
        </TableCell>
    )
}

export default ServiceTrackersItem