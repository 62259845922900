import { useState } from 'react'

type UseToggle = (initial?: boolean) => [boolean, (value?: boolean) => void];

const useToggle: UseToggle = (initial = false) => {
    const [value, setValue] = useState(initial);
    return [value, (newWalue = !value) => setValue(newWalue)]
}

export default useToggle;

