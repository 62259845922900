import { TableRow, TableCell, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FC } from 'react';
import { IEmployeePosition } from '../../API/interfaces/employeePositions';

interface IProps {
    position: IEmployeePosition;
    canEdit: boolean;
    deleteEmployeePosition: (position: IEmployeePosition) => void;
}

const EmployeePositionsItem: FC<IProps> = ({ position, canEdit, deleteEmployeePosition }) => {
    const handleDeleteEmployeePosition = () => deleteEmployeePosition(position);

    return (
        <TableRow>
            <TableCell align="center">{position.position?.name}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={handleDeleteEmployeePosition}><DeleteIcon /></IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default EmployeePositionsItem;