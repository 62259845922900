import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

function Page404() {
    return (
        <div style={{ margin: '90px auto 0', width: 'fit-content' }}>
            <p style={{ textAlign: 'center', fontSize: '20px' }}>Страница не найдена...</p>
            <Link to="/"><Button variant='contained'>вернуться на главную</Button></Link>
        </div>

    )
}

export default Page404