
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

interface IProps {
    availablePermissions: GroupPermissionEnum[],
    addPermission: (permission: GroupPermissionEnum) => Promise<void> 
}

const GroupsPermissionsNew: FC<IProps> = ({ availablePermissions, addPermission }) => {
    const [permission, setPermission] = useState<GroupPermissionEnum | null>();

    const handleAddPermission = () => permission && addPermission(permission);

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Autocomplete sx={{ width: 300 }}
                    options={availablePermissions}
                    onChange={(e, value) => setPermission(value)}
                    renderInput={(params) => <TextField {...params} label="Разрешение" />}
                    disablePortal
            />
            
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleAddPermission}
                disabled={!permission}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default GroupsPermissionsNew;