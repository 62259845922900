import React, { useState } from 'react';
import { Box, Button, FormControl, Input, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DocumentTypeEnum } from '../../API/enums/documentType';
import documentsService from '../../API/services/documents.service';
import { IDocumentCreate } from '../../API/interfaces/document';

interface props {
    employeeId: string;
    refresh: () => void;
}

const EmployeeDocumentsNew = ({ employeeId, refresh }: props) => {
    const [documentValue, setDocumentValue] = useState<DocumentTypeEnum>(DocumentTypeEnum.passport);
    const [comment, setComment] = useState('');
    const [serial, setSerial] = useState('');
    const [number, setNumber] = useState('');
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [file, setFile] = useState<File>();

    const createDocument = () => {
        let payload: IDocumentCreate | object = {};
        if (documentValue === DocumentTypeEnum.inn) {
            payload = 
            {
                employeeId: employeeId,
                comment: comment,
                type: documentValue,
                properties: {
                    number: number,
                },
                file: file as File
            }
        }
        else if (documentValue === DocumentTypeEnum.passport) {
            payload = {
                employeeId: employeeId,
                comment: comment,
                type: documentValue,
                properties: {
                    serial: serial,
                    number: number,
                    date: date
                },
                file: file as File
            }
        }
        else if (documentValue === DocumentTypeEnum.snils) {
            payload = {
                employeeId: employeeId,
                comment: comment,
                type: documentValue,
                properties: {
                    number: number,
                    date: date
                },
                file: file as File
            }
        }
        else if (documentValue === DocumentTypeEnum.untyped) {
            payload = {
                employeeId: employeeId,
                comment: comment,
                type: documentValue,
                properties: {
                    name: name
                },
                file: file as File
            }
        }
        documentsService.create(payload as IDocumentCreate)
        .then(() => refresh())
        .catch(er => console.log(er));
    }

    const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files as FileList;
        setFile(files[0]);
    }

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px" marginBottom='15px'>
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <InputLabel>тип документа</InputLabel>
                    <Select value={documentValue}
                        onChange={(e) => setDocumentValue(e.target.value as DocumentTypeEnum)}>
                        <MenuItem value={DocumentTypeEnum.passport}>
                            {'Паспорт'}
                        </MenuItem>
                        <MenuItem value={DocumentTypeEnum.snils}>
                            {'СНИЛС'}
                        </MenuItem>
                        <MenuItem value={DocumentTypeEnum.inn}>
                            {'ИНН'}
                        </MenuItem>
                        <MenuItem value={DocumentTypeEnum.untyped}>
                            {'Другое'}
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant='filled' sx={{ width: '300px' }}>
                    <TextField
                        type="text"
                        variant="filled"
                        sx={{ width: '300px' }}
                        label='Комментарий'
                        onChange={(e) => setComment(e.target.value)}
                    />
                </FormControl>
                <FormControl variant='filled' required sx={{ width: '300px' }}>
                    <Button variant="contained" color="info" sx={{ width: '300px', height: '56px' }}>
                        <Input type='file' sx={{ width: '300px', height: '100%' }} required onChange={onFileUpload} />
                    </Button>
                </FormControl>
                </Stack>
                <Box sx={{display: 'flex', flexDirection: 'row', width: 'fit-content'}}>
                    {documentValue === DocumentTypeEnum.passport ?
                        <FormControl variant='filled' required sx={{ width: '920px', display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                type="text"
                                variant="filled"
                                required
                                sx={{ width: '300px', marginRight  : '10px' }}
                                label='Серия'
                                onChange={(e) => setSerial(e.target.value)}
                            />
                            <TextField
                                type="text"
                                variant="filled"
                                required
                                sx={{ width: '300px', marginRight: '10px' }}
                                label='Номер'
                                onChange={(e) => setNumber(e.target.value)}
                            />
                            <TextField
                                type="text"
                                variant="filled"
                                required
                                sx={{ width: '300px' }}
                                label='Дата выдачи'
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </FormControl> : <></>}
                    {documentValue === DocumentTypeEnum.inn ?
                        <FormControl variant='filled' required sx={{ width: '300px', display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                type="text"
                                variant="filled"
                                required
                                sx={{ width: '300px' }}
                                label='Номер'
                                onChange={(e) => setNumber(e.target.value)}
                            />
                        </FormControl> : <></>}
                    {documentValue === DocumentTypeEnum.snils ?
                        <FormControl variant='filled' required sx={{ width: '610px', display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                type="text"
                                variant="filled"
                                required
                                sx={{ width: '300px' }}
                                label='Номер'
                                onChange={(e) => setNumber(e.target.value)}
                            />
                            <TextField
                                type="text"
                                variant="filled"
                                required
                                sx={{ width: '300px', marginLeft: '10px' }}
                                label='Дата выдачи'
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </FormControl> : <></>}
                    {documentValue === DocumentTypeEnum.untyped ?
                        <FormControl variant='filled' required sx={{ width: '300px', display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                type="text"
                                variant="filled"
                                required
                                sx={{ width: '300px' }}
                                label='Название документа'
                                onChange={(e) => { setName(e.target.value) }}
                            />
                        </FormControl> : <></>}
                </Box>
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={createDocument}
                disabled={
                    documentValue === DocumentTypeEnum.passport ? !(serial && number && date) : 
                    documentValue === DocumentTypeEnum.inn ? !(number) : 
                    documentValue === DocumentTypeEnum.snils ? !(number && date) :
                    documentValue === DocumentTypeEnum.untyped ? !(name) : !documentValue
                }
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeeDocumentsNew;