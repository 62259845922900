import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import { useProfile } from '../context/Profile';
import { useSettings } from '../context/Settings';

const Loading = () => {
    const [isReady, setIsReady] = useState(false);
    const { isAuth } = useAuth();
    const { loadProfile } = useProfile();
    const { loadSettings } = useSettings();

    useEffect(() => {
        if(!isAuth) return setIsReady(true);
        setIsReady(false);
        Promise.all([
            loadProfile(),
            loadSettings()
        ]).finally(() => setIsReady(true));
    }, [isAuth])

    if(isReady) return <Outlet/>;

    return null;
}

export default Loading;
