import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IPositionCreate, IPosition, IPositionSearch, IPositionUpdate } from '../interfaces/positions.interfaces';

export class PositionsService {
    constructor(private api: ApiClient){}

    public async search(search?: IPositionSearch){
        return this.api.get<SearchResult<IPosition>>('positions', search);
    }

    public async create(payload: IPositionCreate){
        return this.api.post<IPosition>('positions', payload);
    }

    public async update(id: string, payload: IPositionUpdate){
        return this.api.patch<IPosition>('positions/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('positions/' + id);
    }
}

export default new PositionsService(apiClient);