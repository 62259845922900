import { FC, ReactNode } from 'react';
import AuthProvider from './Auth';
import ProfileProvider from './Profile';
import SettingsProvider from './Settings';

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <AuthProvider>
            <ProfileProvider>
                <SettingsProvider>
                    { children } 
                </SettingsProvider>
            </ProfileProvider>
        </AuthProvider>
    )
}


export default Context;