import { FC, useState } from 'react';
import { IPaymentCreate } from '../../API/interfaces/payments.interfaces';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import SearchDate from '../SearchDate';
import { TODAY } from '../../utils/dateFormat';

interface IProps {
    onCreate: (payload: IPaymentCreate) => Promise<void>,
    employeeId: number
}

const EmployeePaymentNew: FC<IProps> = ({ onCreate, employeeId }) => {

    const [amount, setAmount] = useState<number>(0);
    const [date, setDate] = useState<string>(TODAY);
    const [comment, setComment] = useState<string>('');

    const isValid = amount && date;

    const [error, setError] = useState('');

    const handleCreate = () => isValid && onCreate({
        employeeId,
        amount,
        dateAt: date as unknown as Date,
        comment
    }).catch((err) => err.message && setError(err.message)); 
    
    return (
        <Box sx={{ marginTop: '20px' }}>
            <Stack direction="row" flexWrap="wrap" gap="10px">
                <TextField
                    type="number"
                    variant="filled"
                    sx={{ width: '300px' }}
                    required={true}
                    label='Сумма'
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                />
                <SearchDate date={date} setDate={setDate} />
                <TextField
                    type="text"
                    variant="filled"
                    sx={{ width: '300px' }}
                    required={true}
                    label='Комментарий'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </Stack>
            {
                error && <Typography marginTop="20px" color="error">{error}</Typography>
            }
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!isValid}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeePaymentNew;