import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import employeesService from '../../API/services/employees.service';
import { IEmployee } from '../../API/interfaces/employees.interfaces';
import EmployeeGroupsNew from './EmployeePositionsNew';
import EmployeeGroupsItem from './EmployeePositionsItem';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';
import { IPosition } from '../../API/interfaces/positions.interfaces';
import positionsService from '../../API/services/positions.service';
import employeePositionsService from '../../API/services/employeePositions.service';
import { IEmployeePosition, IEmployeePositionCreate } from '../../API/interfaces/employeePositions';

const EmployeePositions = () => {
    const { employeeId } = useParams();
    const [positions, setPositions] = useState<IPosition[]>([]);
    const [employee, setEmployee] = useState<IEmployee>();
    const [employeePositions, setEmployeePositions] = useState<IEmployeePosition[]>();
    const [showAddForm, setShowAddForm] = useState(false);
    
    const hasPermission = usePermissions();
    const canRead = hasPermission(GroupPermissionEnum.EmployeeRead) && hasPermission(GroupPermissionEnum.EmployeesOnPositionsRead);
    const canEdit = hasPermission(GroupPermissionEnum.EmployeesOnPositionsEdit);

    if(!canRead){
        return <Navigate to="/" replace />
    }

    const searchEmployeePositions = async () => {
        return employeePositionsService.search({ employeeId })
            .then((res) => res.items)
            .then(setEmployeePositions);
    }

    const createEmployeePosition = async (payload: IEmployeePositionCreate) => {
        return employeePositionsService.create(payload)
            .then(searchEmployeePositions)
            .then(() => setShowAddForm(false));
    }

    const deleteEmployeePosition = async (payload: IEmployeePosition) => {
        return employeePositionsService.delete(payload.id).then(searchEmployeePositions);
    }

    useEffect(() => {
        employeesService.searchEmployees({ id: employeeId })
            .then((res) => res.items[0])
            .then(setEmployee)
            .then(searchEmployeePositions)
            .catch(console.log);
    }, [employeeId])

    useEffect(() => { 
        positionsService.search()
            .then((res) => res.items)
            .then(setPositions)
            .catch(console.log);
     }, [])

    if (!employee || !employeePositions) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600 }} />
        </Container>
    )
    
    const assignedPositions = employeePositions.map((item) => item.positionId);
    const availablePositions = positions.filter((item) => !assignedPositions.includes(item.id));

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/employees"><Typography fontSize={18}>Сотрудники</Typography></Link>
                <Typography color="black" fontSize={18}>Должности сотрудника &quot;{employee ? `${employee.firstName} ${employee.lastName}` : ''}&quot;</Typography>
            </Breadcrumbs>
            
            {
                canEdit && availablePositions.length > 0 && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowAddForm(v => !v)}>
                            {showAddForm ? 'Закрыть' : 'Добавить'}
                        </Button>
                        {
                            showAddForm && <EmployeeGroupsNew employee={employee} availablePositions={availablePositions} createEmployeePosition={createEmployeePosition} />
                        }
                    </Box>
                )
            }

            {
                employeePositions.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Сотрудник</TableCell>
                                    {
                                        canEdit && <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                    }
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employeePositions.map((position) => (
                                        <EmployeeGroupsItem 
                                            position={position}
                                            canEdit={canEdit} 
                                            deleteEmployeePosition={deleteEmployeePosition} 
                                            key={position.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>   
                ) 
            }
            
        </Container>
    )
}

export default EmployeePositions;