import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IGroup, IGroupCreate, IGroupUpdate } from '../../API/interfaces/groups.interfaces';
import groupsService from '../../API/services/groups.service';
import GroupsNew from './GroupsNew';
import GroupsItem from './GroupsItem';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePagination from '../../hooks/usePagination';

const Groups: FC = () => {
    const hasPermission = usePermissions();
    const { limit, offset, setCount, Pagination } = usePagination();

    const [groups, setGroups] = useState<IGroup[]>();
    const [showForm, setShowForm] = useState(false);

    if(!hasPermission(GroupPermissionEnum.GroupRead)){
        return <Navigate to="/" replace />
    }

    const searchGroups = () => {
        groupsService.searchGroups({ limit, offset })
            .then((res) => {
                setGroups(res.items)
                setCount(res.count)
            })
            .catch((err) => console.log(err))
    }

    const createGroup = (payload: IGroupCreate) => {
        groupsService.createGroup({name: payload.name})
            .then(() => setShowForm(false))
            .then(searchGroups)
            .catch(er => console.log(er))
    }

    const updateGroup = (id: string, payload: IGroupUpdate) => {
        groupsService.updateGroup(id, payload)
            .then(searchGroups)
            .catch(er => console.log(er))
    }

    const deleteGroup = (id: string) => {
        groupsService.deleteGroup(id)
            .then(searchGroups)
            .catch(er => console.log(er))
    }

    useEffect(searchGroups, [limit, offset]);

    if(!groups) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600  }}/>
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Группы</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.GroupEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить группу' }
                        </Button>
                        { showForm && <GroupsNew onCreate={createGroup} /> }
                    </Box>
                )
            }

            {
                groups.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" >Группа</TableCell>
                                    <TableCell align="center" width="120">Доступы</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && <TableCell align="center" width="120">Сотрудники</TableCell>
                                    }
                                    {
                                        hasPermission(GroupPermissionEnum.GroupEdit) && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    groups.map(group => (
                                        <GroupsItem key={group.id} group={group} onUpdate={updateGroup} onDelete={deleteGroup} />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <Pagination />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default Groups;