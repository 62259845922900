import { TableRow, TableCell, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FC } from 'react';
import { IEmployeeGroup } from '../../API/interfaces/employeeGroups.interfaces';
import { IGroupLookUp } from '../../API/interfaces/groups.interfaces';

interface IProps {
    employeeGroup: IEmployeeGroup;
    groups: IGroupLookUp[];
    canEdit: boolean;
    deleteEmployeeGroup: (payload: IEmployeeGroup) => void;
}

const EmployeeGroupsItem: FC<IProps> = ({ groups, employeeGroup, canEdit, deleteEmployeeGroup }) => {
    const group = groups.find((item) => item.id === employeeGroup.groupId);

    const handleDeleteEmployeeGroup = () => deleteEmployeeGroup(employeeGroup);

    return (
        <TableRow>
            <TableCell align="center">{group?.name}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={handleDeleteEmployeeGroup}><DeleteIcon /></IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default EmployeeGroupsItem;