import {  useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IGroup } from '../../API/interfaces/groups.interfaces';
import groupsService from '../../API/services/groups.service';
import GroupPermissionsItem from './GroupPermissionsItem';
import GroupPermissionsNew from './GroupPermissionsNew';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';

const GroupPermissions = () => {
    const { groupId } = useParams()
    const [group, setGroup] = useState<IGroup>();
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.GroupRead)){
        return <Navigate to="/" replace />
    }
        
    const searchGroup = async () => {
        groupsService.searchGroups({id: groupId})
            .then((res) => res.items[0])
            .then(setGroup);
    }

    useEffect(() => { searchGroup() }, [groupId])
    
    if(!group) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600  }}/>
        </Container>
    )

    const availablePermissions = Object.values(GroupPermissionEnum).filter((per) => !group.permissions.includes(per))

    const canEdit = hasPermission(GroupPermissionEnum.GroupEdit);

    const addPermission = async (permission: GroupPermissionEnum) => {
        return groupsService.updateGroup(group.id, {
            permissions: [...group.permissions, permission]
        })
            .then(searchGroup)
            .then(() => setShowForm(false))
    }

    const removePermission = async (permission: GroupPermissionEnum) => {
        return groupsService.updateGroup(group.id, {
            permissions: group.permissions.filter((p) => p !== permission)
        }).then(searchGroup);
    }

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/groups"><Typography fontSize={18}>Группы</Typography></Link>
                <Typography color="black" fontSize={18}>Разрешения группы &quot;{group ? group.name : ''}&quot;</Typography>
            </Breadcrumbs>

            {
                canEdit && availablePermissions.length > 0 && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить разрешение' }
                        </Button>
                        { 
                            showForm && <GroupPermissionsNew availablePermissions={availablePermissions} addPermission={addPermission} /> 
                        }
                    </Box>
                )
            }

            {
                group.permissions.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Разрешение</TableCell>
                                    {
                                        canEdit && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    group.permissions.map((permission) => (
                                        <GroupPermissionsItem key={permission}
                                            permission={permission}
                                            canEdit={canEdit}
                                            removePermission={removePermission}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
        
}

export default GroupPermissions;