import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { IEmployee } from '../API/interfaces/employees.interfaces';
import employeesService from '../API/services/employees.service';

interface IProfileContext {
    profile: IEmployee | null;
    loadProfile: () => Promise<void>;
}

export const ProfileContext = createContext({} as IProfileContext);

export const useProfile = () => useContext(ProfileContext);

const ProfileProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [profile, setProfile] = useState<IEmployee | null>(null);

    async function loadProfile(){
        return employeesService.getProfile().then(setProfile);
    }

    return (
        <ProfileContext.Provider value={{ profile, loadProfile }}>
           { children } 
        </ProfileContext.Provider>
    )
}

export default ProfileProvider;




