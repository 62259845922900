import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/Auth';

const PrivateRoutes: FC = () => {
    const { isAuth } = useAuth();
    const location = useLocation();
    
    return isAuth ? <Outlet/> : <Navigate to='/auth' replace={true} state={{ from: location.pathname }} />
}

export default PrivateRoutes;