import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IContractService, IContractServiceCreate, IContractServiceSearch, IContractServiceUpdate } from '../interfaces/contractServices.interfaces';

export class ContractServicesService {
    constructor(private api: ApiClient){}

    public async search(search?: IContractServiceSearch){
        return this.api.get<SearchResult<IContractService>>('contracts-on-services', search);
    }

    public async create(payload: IContractServiceCreate){
        return this.api.post<IContractService>('contracts-on-services', payload);
    }

    public async update(id: string, payload: IContractServiceUpdate){
        return this.api.patch<IContractService>('contracts-on-services/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('contracts-on-services/' + id);
    }
}

export default new ContractServicesService(apiClient);