import {  IconButton, MenuItem, Select, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IService } from '../../API/interfaces/services.interfaces';
import { IContractService, IContractServiceCreate } from '../../API/interfaces/contractServices.interfaces';
import { IServiceType } from '../../API/interfaces/serviceTypes.interfaces';
import { IContract } from '../../API/interfaces/contracts.interfaces';

interface IProps {
    types: IServiceType[],
    services: IService[],
    contract: IContract,
    contractService: IContractService,
    canEdit: boolean,
    onUpdate: (payload: Omit<IContractServiceCreate, 'contractId'>) => void,
    onDelete: () => void,
}

const ContractServicesItem: FC<IProps> = ({ types, services, contractService, canEdit, onUpdate, onDelete, contract }) => {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [typeId, setTypeId] = useState<string>(contractService.service?.serviceTypeId || '');
    const typeServices =  services.filter(service => !typeId || !service.serviceTypeId ||service.serviceTypeId === typeId);
    const [serviceId, setServiceId] = useState<string>(contractService.service.id);
    const [costForCounterparty, setCostForCounterparty] = useState<number>(contractService.costForCounterparty);
    const [costForEmployee, setCostForEmployee] = useState<number>(contractService.costForEmployee);
    
    const resetValues = () => {
        setEditMode(false);
        setTypeId(contractService.service?.serviceTypeId || '');
        setServiceId(contractService.service.id);
        setCostForCounterparty(contractService.costForCounterparty);
        setCostForEmployee(contractService.costForEmployee);
    }

    const handleUpdate = () => {
        onUpdate({ serviceId, costForCounterparty, costForEmployee })
        setEditMode(false);
    }

    if(canEdit && isEditMode) return (
        <TableRow hover={true} >
            <TableCell >
                <Select 
                    value={typeId} 
                    onChange={e => setTypeId(e.target.value)} 
                    variant='outlined' 
                    size='small'
                    fullWidth
                >
                    <MenuItem value={contractService.service.serviceTypeId} key={contractService.service.serviceTypeId}>
                        {contractService.service.serviceType?.name || 'Не выбрано'}
                    </MenuItem>
                    {
                        types.map((type) => (
                            <MenuItem value={type.id} key={type.id}>
                                {type.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell >
                <Select 
                    value={serviceId} 
                    onChange={e => setServiceId(e.target.value)} 
                    variant='outlined' 
                    size='small'
                    fullWidth 
                    required
                >
                    <MenuItem value={contractService.service.id}>
                        {contractService.service.name}
                    </MenuItem>   
                    {
                        typeServices.map((service) => (
                            <MenuItem value={service.id} key={service.id}>
                                {service.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            {
                !contract.costForCounterpartyFixed && (
                    <TableCell >
                        <TextField
                            type="number"
                            variant='outlined'
                            value={costForCounterparty}
                            onChange={e => setCostForCounterparty(+e.target.value)}
                            fullWidth
                            required
                            size='small'
                        />
                    </TableCell>
                )
            }
            <TableCell >
                <TextField
                    type="number"
                    variant='outlined'
                    value={costForEmployee}
                    onChange={e => setCostForEmployee(+e.target.value)}
                    fullWidth
                    required
                    size='small'
                />
            </TableCell>
            <TableCell >
                <IconButton onClick={resetValues}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
        
    return (
        <TableRow hover={true} >
            <TableCell >
                <Typography textAlign='center'>{contractService.service.serviceType?.name}</Typography>
            </TableCell>
            <TableCell >
                <Typography textAlign='center'>{contractService.service.name}</Typography>
            </TableCell>
            {
                !contract.costForCounterpartyFixed && (
                    <TableCell >
                        <Typography textAlign='center'>{contractService.costForCounterparty}</Typography>
                    </TableCell>
                )
            }
            <TableCell >
                <Typography textAlign='center'>{contractService.costForEmployee}</Typography>
            </TableCell>
            {
                canEdit && (
                    <TableCell >
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ContractServicesItem;