import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PositionsNew from './PositionsNew';
import PositionsItem from './PositionsItem';
import positionsService from '../../API/services/positions.service';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import usePermissions from '../../hooks/usePermissions';
import usePagination from '../../hooks/usePagination';
import { IPosition, IPositionCreate, IPositionUpdate } from '../../API/interfaces/positions.interfaces';

const Positions: FC = () => {
    const hasPermission = usePermissions();
    const { limit, offset, setCount, Pagination } = usePagination();
    const [positions, setPositions] = useState<IPosition[]>();
    const [showForm, setShowForm] = useState(false);
    const canEdit = hasPermission(GroupPermissionEnum.PositionEdit);

    if (!hasPermission(GroupPermissionEnum.PositionRead)) {
        return <Navigate to="/" replace />
    }

    const searchPositions = async () => {
        return positionsService.search({ limit, offset })
            .then(res => {
                setPositions(res.items);
                setCount(res.count);
            })
            .catch(console.log);
    }

    useEffect(() => {
        searchPositions();
    }, [limit, offset]);

    if (!positions) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600 }} />
        </Container>
    )

    const createPosition = async (payload: IPositionCreate) => {
        return positionsService.create(payload)
            .then(searchPositions)
            .then(() => setShowForm(false));
    }

    const updatePosition = async (id: string, payload: IPositionUpdate) => {
        return positionsService.update(id, payload)
            .then(searchPositions);
    }

    const deletePosition = async (id: string) => {
        return positionsService.delete(id)
            .then(searchPositions);
    }

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Должности</Typography>
            </Breadcrumbs>

            {
                canEdit && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить должность'}
                        </Button>
                        {showForm && <PositionsNew createPosition={createPosition} />}
                    </Box>
                )
            }

            {
                positions.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Должность</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.PositionsOnServicesRead) && (<TableCell align="center">Услуги</TableCell>)
                                    }
                                    {
                                        canEdit && <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    positions.map(position => (
                                        <PositionsItem key={position.id}
                                            position={position}
                                            canEdit={canEdit}
                                            onUpdate={updatePosition}
                                            onDelete={deletePosition}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <Pagination />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default Positions;