import { Box, Button, Autocomplete, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IEmployeeGroupCreate } from '../../API/interfaces/employeeGroups.interfaces';
import { IEmployeeLookUp } from '../../API/interfaces/employees.interfaces';
import { IGroupLookUp } from '../../API/interfaces/groups.interfaces';

interface IProps {
    employee: IEmployeeLookUp;
    groups: IGroupLookUp[];
    createEmployeeGroup: (payload: IEmployeeGroupCreate) => Promise<void>;
}

const EmployeeGroupsNew: FC<IProps> = ({ employee, groups, createEmployeeGroup }) => {
    const [group, setGroup] = useState<IGroupLookUp | null>(null);

    const handleCreateEmployeeGroup = () => group && createEmployeeGroup({ employeeId: employee.id, groupId: group.id });

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Autocomplete sx={{ width: 300 }}
                options={groups}
                getOptionLabel={(group) => group.name}
                onChange={(e, value) => setGroup(value)}
                renderInput={(params) => <TextField {...params} label="Группа" />}
                disablePortal
            />
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreateEmployeeGroup}
                disabled={!group}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeeGroupsNew;