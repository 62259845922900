import { useEffect, useReducer, useState } from 'react';
import { IEmploymentPlace, IEmploymentPlaceCreate, IEmploymentPlaceUpdate } from '../../API/interfaces/employmentPlaces.interfaces';
import employmentPlacesService from '../../API/services/employmentPlaces.service';
import usePagination from '../../hooks/usePagination';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import EmploymentPlacesItem from './EmploymentPlacesItem';
import EmploymentPlacesNew from './EmploymentPlacesNew';
import usePermissions from '../../hooks/usePermissions';

const EmploymentPlaces = () => {
    const hasPermission = usePermissions();
    const { limit, offset, setCount, Pagination } = usePagination();
    
    const [employmentPlaces, setEmploymentPlaces] = useState<IEmploymentPlace[]>();
    const [showForm, toggleShowForm] = useReducer(v => !v, false);

    if(!hasPermission(GroupPermissionEnum.EmploymentPlaceRead)){
        return <Navigate to="/" replace />
    }

    const searchEmploymentPlaces = () => {
        employmentPlacesService.search({limit, offset})
        .then(r => {
            setEmploymentPlaces(r.items);
            setCount(r.count)
        })
        .catch(console.log)
    }

    const createEmploymentPlace = (payload: IEmploymentPlaceCreate) => {
        employmentPlacesService.create(payload)
        .then(searchEmploymentPlaces)
        .then(toggleShowForm)
        .catch(console.log);
    }

    const updateEmploymentPlace = (id: string, payload: IEmploymentPlaceUpdate) => {
        employmentPlacesService.update(id, payload)
        .then(searchEmploymentPlaces)
        .catch(console.log)
    }

    const deleteEmploymentPlace = (id: string) => {
        employmentPlacesService.delete(id)
        .then(searchEmploymentPlaces)
        .catch(console.log)
    }

    useEffect(searchEmploymentPlaces, [limit, offset])

    {
        if(!employmentPlaces) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600  }}/>
        </Container>
    )
        }

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Места трудоустройства</Typography>
            </Breadcrumbs>

            <Box sx={{ marginTop: '40px' }}>
                <Button variant="contained" size="large" onClick={toggleShowForm}>
                    { showForm ? 'Закрыть' : 'Добавить место' }
                </Button>
                { showForm && <EmploymentPlacesNew onCreate={createEmploymentPlace} /> }
            </Box>

            {
               employmentPlaces && employmentPlaces.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600 , padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Название</TableCell>
                                    {
                                       hasPermission(GroupPermissionEnum.EmploymentPlaceEdit) && 
                                       <TableCell width="120px"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    employmentPlaces.map(place => (
                                        <EmploymentPlacesItem
                                            place={place}
                                            canEdit={hasPermission(GroupPermissionEnum.EmploymentPlaceEdit)}
                                            onUpdate={payload => updateEmploymentPlace(place.id, payload)}
                                            onDelete={() => deleteEmploymentPlace(place.id)}
                                            key={place.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <Pagination />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default EmploymentPlaces;