import {  IconButton, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IEmployeeGroup } from '../../API/interfaces/employeeGroups.interfaces';

interface IProps {
    groupEmployee: IEmployeeGroup,
    canEdit: boolean,
    deleteGroupEmployee: (id: string) => Promise<void>
}

const GroupEmployeesItem: FC<IProps> = ({ groupEmployee, canEdit, deleteGroupEmployee }) => {
    const handleDelete = () => deleteGroupEmployee(groupEmployee.id);

    return (
        <TableRow>
            <TableCell align="center">{groupEmployee.employee?.lastName} {groupEmployee.employee?.firstName}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default GroupEmployeesItem;