import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { IContract, IContractUpdate } from '../../API/interfaces/contracts.interfaces';
import { IContractEmployee } from '../../API/interfaces/contractEmployees';
import contractEmployeesService from '../../API/services/contractEmployees.service';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

interface IProps {
    contract: IContract,
    onUpdate: (payload: IContractUpdate) => void,
    onDelete: () => void
}

const ObjectContractsItem: FC<IProps> = ({ contract, onUpdate, onDelete }) => {
    const contractId = contract.id;
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState<string>(contract.name);
    const [contractNumber, setContractNumber] = useState<string>(contract.contractNumber);
    const [counterParty, setCounterParty] = useState<string>(contract.counterParty);
    const [region, setRegion] = useState<string>(contract.region);
    const [address, setAddress] = useState<string>(contract.address);
    const [costForCounterpartyFixed, setFixedCost] = useState<number>
        (contract.costForCounterpartyFixed? contract.costForCounterpartyFixed : 0);
    const [contractEmployees, setContractEmployees] = useState<IContractEmployee[]>([]);

    const hasPermission = usePermissions();

    const resetValues = () => {
        setEditMode(false);
        setName(contract.name);
        setContractNumber(contract.contractNumber);
        setCounterParty(contract.counterParty);
        setRegion(contract.region);
        setAddress(contract.address);
        setFixedCost(contract.costForCounterpartyFixed? contract.costForCounterpartyFixed : 0);
    }

    const searchContractEmployees = () => {
        contractEmployeesService.search({ contractId })
            .then(res => res.items)
            .then(setContractEmployees)
            .catch(console.log);
    }

    useEffect(() => {
        searchContractEmployees();
    },[contractId])
    
    const handleSubmit = () => {
        onUpdate({ name, contractNumber, counterParty, region, address, costForCounterpartyFixed });
        setEditMode(false);
    } 

    if(hasPermission(GroupPermissionEnum.ContractEdit) && isEditMode) return (
        <TableRow>
            <TableCell>
                <TextField
                    size='small'
                    type="text"
                    variant="outlined"
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    size='small'
                    type="text"
                    variant="outlined"
                    required={true}
                    value={contractNumber}
                    onChange={(e) => setContractNumber(e.target.value)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    size='small'
                    type="text"
                    variant="outlined"
                    required={true}
                    value={counterParty}
                    onChange={(e) => setCounterParty(e.target.value)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    size='small'
                    type="text"
                    variant="outlined"
                    required={true}
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    size='small'
                    type="text"
                    variant="outlined"
                    required={true}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
            </TableCell>
            <TableCell>
            <TextField
                    type="text"
                    variant="filled"
                    label='Фиксированная стоимость'
                    value={costForCounterpartyFixed}
                    onChange={(e) => setFixedCost(Number(e.target.value))}
                />
            </TableCell>
            <TableCell align="center">
                <Link to={`/contracts/${contract.id}/services`}>
                    <Button variant='outlined'>{
                    contract.services? contract.services.length : 0
                    }</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <Link to={`/contracts/${contract.id}/employees`}>
                    <Button variant='outlined'>{
                    contractEmployees? contractEmployees.length : 0
                    }</Button>
                </Link>
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={resetValues}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleSubmit}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{contract.name}</TableCell>
            <TableCell align="center">{contract.contractNumber}</TableCell>
            <TableCell align="center">{contract.counterParty}</TableCell>
            <TableCell align="center">{contract.region}</TableCell>
            <TableCell align="center">{contract.address}</TableCell>
            <TableCell align="center">{contract.costForCounterpartyFixed || '-'}</TableCell>
            {
                hasPermission(GroupPermissionEnum.ContractsOnServicesRead) && (
                    <TableCell align="center">
                        <Link to={`/contracts/${contract.id}/services`}>
                            <Button variant='outlined'>{
                            contract.services? contract.services.length : 0
                            }</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.ContractsOnEmployeesRead) && (
                    <TableCell align="center" >
                        <Link to={`/contracts/${contract.id}/employees`}>
                            <Button variant='outlined' >{
                            contractEmployees? contractEmployees.length : 0
                            }</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.ContractEdit) && (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ObjectContractsItem;