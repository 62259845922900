import { FC, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ServicesNew from './ServiceNew';
import { IServiceCreate, IService, IServiceUpdate } from '../../API/interfaces/services.interfaces';
import servicesService from '../../API/services/services.service';
import ServicesItem from './ServiceItem';
import { IServiceType } from '../../API/interfaces/serviceTypes.interfaces';
import serviceTypesService from '../../API/services/serviceTypes.service';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';

const Services: FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [services, setServices] = useState<IService[]>();
    const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    if(!hasPermission(GroupPermissionEnum.ServiceRead)){
        return <Navigate to="/" replace />
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const searchServices = () => {
        servicesService.searchServices({ limit: rowsPerPage, offset: page * rowsPerPage })
            .then(res => {
                setServices(res.items); 
                setCount(res.count);
            })
            .catch(console.log);
    }

    const createService = (payload: IServiceCreate) => {
        servicesService.createService(payload)
            .then(searchServices)
            .catch(console.log);
    }

    const updateService = (id: string, payload: IServiceUpdate) => {
        servicesService.updateService(id, payload)
            .then(searchServices)
            .catch(console.log);
    }

    const deleteService = (id: string) => {
        servicesService.deleteService(id)
            .then(searchServices)
            .catch(console.log);
    }

    useEffect(searchServices, [page, rowsPerPage]);

    useEffect(() => {
        serviceTypesService.search()
            .then(res => res.items)
            .then(setServiceTypes)
            .catch(console.log)
    }, [])

    if(!services) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600  }}/>
        </Container>
    )

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Услуги</Typography>
            </Breadcrumbs>

            {
                hasPermission(GroupPermissionEnum.ServiceEdit) && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить услугу' }
                        </Button>
                        { showForm && <ServicesNew serviceTypes={serviceTypes} onCreate={createService} /> }
                    </Box>
                )
            }
            {
                services.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" width="300">Название</TableCell>
                                    <TableCell align="center" width="300">Тип услуги</TableCell>
                                    <TableCell align="center" width="300">Ед. измерения</TableCell>
                                    <TableCell width="120px"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    services.map(service => (
                                        <ServicesItem
                                            service={service}
                                            serviceTypes={serviceTypes}
                                            canEdit={hasPermission(GroupPermissionEnum.ServiceEdit)}
                                            onUpdate={payload => updateService(service.id, payload)}
                                            onDelete={() => deleteService(service.id)}
                                            key={service.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={count}
                            page={page}
                            onPageChange={(_, value) => setPage(value)}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            component="div"
                        />
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default Services;