import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IPaymentCreate, IPaymentSearch, IPayment, IPaymentUpdate } from '../interfaces/payments.interfaces';

export class PaymentsService {
    constructor(private api: ApiClient, private route: string){}

    public async search(search?: IPaymentSearch){
        return this.api.get<SearchResult<IPayment>>(this.route, search);
    }

    public async create(payload: IPaymentCreate){
        return this.api.post<IPayment>(this.route, payload);
    }

    public async update(id: string, payload: IPaymentUpdate){
        return this.api.patch<IPayment>(`${this.route}/${id}`, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>(`${this.route}/${id}`);
    }
}

export default new PaymentsService(apiClient, 'payments');