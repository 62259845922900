import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IGroupCreate, IGroup, IGroupSearch, IGroupUpdate, IGroupLookUp } from '../interfaces/groups.interfaces';

export class GroupsService {
    constructor(private api: ApiClient){}
  
    public async lookUp(search?: IGroupSearch){
        return this.api.get<SearchResult<IGroupLookUp>>('user-groups/lookup', search);
    }

    public async searchGroups(search?: IGroupSearch){
        return this.api.get<SearchResult<IGroup>>('user-groups', search);
    }

    public async createGroup(payload: IGroupCreate){
        return this.api.post<IGroup>('user-groups', payload);
    }

    public async updateGroup(id: string, payload: IGroupUpdate){
        return this.api.patch<IGroup>('user-groups/' + id, payload);
    }

    public async deleteGroup(id: string){
        return this.api.delete<void>('user-groups/' + id);
    }
}

export default new GroupsService(apiClient);