import { useSearchParams } from 'react-router-dom'

const useSearchState = (key: string, defaultValue = ''): [string, (value: string) => void] => {
    const [params, setParams] = useSearchParams();

    const value = params.get(key) || localStorage.getItem(key) || defaultValue;
    const setValue = (value: string) => {
        if(value){
            localStorage.setItem(key, value);
            params.set(key, value);
            setParams(params);
            return;
        }
        localStorage.removeItem(key);
        params.delete(key);
        setParams(params);
    }
    
    return [ value, setValue ]
}

export default useSearchState