import React, { useState } from 'react';
import { Box, TextField, Button, Container,  Stack, Typography } from '@mui/material';
import cleaningIcon from '../assets/images/cleaning-icon.png';
import { useAuth } from '../context/Auth';
import { Navigate, useLocation } from 'react-router-dom';

const AuthPage = () => {
    const { isAuth, signIn } = useAuth();
    const location = useLocation();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        signIn({ login, password }).catch(() => setError('Неверный логин или пароль'));
    }

    if(isAuth) return <Navigate to={location.state?.from || '/'} />

    return (
        <Container maxWidth="xs" sx={{ maxWidth:'200px', padding: '200px 30px' }}>
            <form onSubmit={handleSubmit} style={{ display: 'grid', rowGap: '30px' }}>
                <Stack direction="row" alignItems="center" justifyContent="center" gap="10px" >
                    <img src={cleaningIcon} style={{ width: 48 }} alt={'logo'} />
                    <Typography color='black' fontSize={32} >EMS cleaning</Typography>
                </Stack>
                <Box sx={{ display: 'grid', rowGap: '4px', width: '100%', maxWidth: '350px', justifySelf:'center' }}>
                    <Typography color='black' sx={{marginTop:'20px'}}>Логин</Typography>
                    <TextField placeholder='Введите логин' variant='filled' fullWidth type='text' value={login} onChange={e => setLogin(e.target.value)}/>
                </Box>
                <Box sx={{ display: 'grid', rowGap: '4px', width: '100%', maxWidth: '350px', justifySelf:'center' }}>
                    <Typography color='black'>Пароль</Typography>
                    <TextField placeholder='Введите пароль' variant='filled' fullWidth  type='password' value={password} onChange={e => setPassword(e.target.value)}/>
                </Box>
                <Button size="large" variant='contained' type="submit" fullWidth sx={{  maxWidth: '220px', justifySelf: 'center' }} disabled={!(login && password)}>Войти</Button>

                {
                    error && <Typography color='error' textAlign="center">{error}</Typography>
                }
                {/* <p style={{ fontSize: '15px', margin: '7px 0 10px 4px' }}>
                    Забыли пароль?
                    <span style={{ color: '#1876d1', cursor: 'pointer' }}> Вспомнить</span>
                </p> */}
            </form>
        </Container>
    )
}

export default AuthPage;