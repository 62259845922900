import apiClient, { ApiClient } from '../apiClient';
import { ISettings } from '../interfaces/settings';

export class ServiceTypesService {
    constructor(private api: ApiClient){}

    getSettings(){
        return this.api.get<ISettings>('settings');
    }

    updateSettings(payload: ISettings){
        return this.api.patch<ISettings>('settings', payload);
    }
}

export default new ServiceTypesService(apiClient);