import { FC, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import contractsService from '../API/services/contracts.service';
import { IContract } from '../API/interfaces/contracts.interfaces';

interface IProps {
    contractId: string,
    setContractId: (value: string) => void,
    objectId?: string,
    employeeId?: string
}

const SearchContract: FC<IProps> = ({ contractId, setContractId, objectId, employeeId }) => {
    const [contracts, setContracts] = useState<IContract[]>([]);
    const contract = (contractId && contracts.find((item) => item.id == contractId)) || null;

    useEffect(() => {
            contractsService.searchContracts({ ...(objectId && { objectId }), ...(employeeId && { employeeId }) })
            .then(res => res.items)
            .then(setContracts)
            .catch(console.log)
    }, [objectId, employeeId])
    

    return (
        <Autocomplete sx={{ width: 300 }}
            options={contracts}
            getOptionLabel={(value) => value.name}
            value={contract}
            onChange={(e, value) => setContractId(value?.id || '')}
            renderInput={(params) => <TextField {...params} label="Подразделение" />}
            disablePortal
        />
    )
}

export default SearchContract;