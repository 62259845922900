
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IEmployeeLookUp } from '../../API/interfaces/employees.interfaces';

interface IProps {
    availableEmployees: IEmployeeLookUp[];
    createContractEmployee: (payload: string) => void;
    setShowForm: React.Dispatch<React.SetStateAction<boolean>>
}

const ContractEmployeesNew: FC<IProps> = ({ createContractEmployee, availableEmployees, setShowForm }) => {
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployeeLookUp | null>(null);

    const handleCreate = () => {
        selectedEmployee && createContractEmployee(selectedEmployee.id);
        setShowForm(false);
    };

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Autocomplete sx={{ width: 300 }}
                options={availableEmployees}
                getOptionLabel={(option) => option.fullName}
                onChange={(e, value) => setSelectedEmployee(value)}
                renderInput={(params) => <TextField {...params} label="Сотрудник" />}
                disablePortal
            />
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreate}
                disabled={!selectedEmployee}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default ContractEmployeesNew;