import { Breadcrumbs, Stack, TextField, Typography, Container, Paper, Button } from '@mui/material';
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useProfile } from '../../context/Profile';
import { useSettings } from '../../context/Settings';

const Settings = () => {
    const { daysToEdit, updateSettings } = useSettings();
    const { profile } = useProfile();

    const [daysToEditField, setDaysToEditField] = useState(daysToEdit || 1);

    if(!profile?.isSuperUser) return <Navigate to="/" />

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Настройки</Typography>
            </Breadcrumbs>

            <Stack component={Paper} elevation={6} sx={{ margin: '40px auto 0', width: 'fit-content', padding: '5%' }}>
                <TextField
                    type='number'
                    variant='filled'
                    size='medium'
                    label='кол-во дней для обновления значений в табеле'
                    sx={{ width: '400px', marginBottom: '24px' }}
                    value={daysToEditField}
                    onChange={(e) => profile?.isSuperUser && setDaysToEditField(+e.target.value)}
                />
                <Button variant='contained' size='large' onClick={() => profile?.isSuperUser && updateSettings({ daysToEdit: daysToEditField })}>
                    Сохранить
                </Button>

            </Stack>

        </Container>

    )
}

export default Settings;