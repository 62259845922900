import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IDocument, IDocumentCreate, IDocumentsSearch, IDocumentUpdate } from '../interfaces/document';

export class DocumentsService {
    constructor(private api: ApiClient){}

    search(search?: IDocumentsSearch){
        return this.api.get<SearchResult<IDocument>>('documents', search);
    }

    create(payload: IDocumentCreate){
        return this.api.post<IDocument>('documents', this.api.setFormData(payload));
    }

    download(fileName: string){
        return this.api.download('documents/' + fileName, fileName);
    }

    update(id: string, payload: IDocumentUpdate){
        return this.api.patch<IDocument>('documents/' + id, this.api.setFormData(payload));
    }

    delete(id: string){
        return this.api.delete<void>('documents/' + id);
    }
}

export default new DocumentsService(apiClient);