import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IContract } from '../../API/interfaces/contracts.interfaces';
import { IEmployeeLookUp } from '../../API/interfaces/employees.interfaces';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { IContractEmployee } from '../../API/interfaces/contractEmployees';
import contractEmployeesService from '../../API/services/contractEmployees.service';
import contractsService from '../../API/services/contracts.service';
import employeesService from '../../API/services/employees.service';
import usePermissions from '../../hooks/usePermissions';
import ContractEmployeesItem from './ContractEmployeesItem';
import ContractEmployeesNew from './ContractEmployeesNew';

const ContractEmployees: FC = () => {
    const { contractId } = useParams();
    const [contract, setContract] = useState<IContract>();
    const [contractEmployees, setContractEmployees] = useState<IContractEmployee[]>([]);
    const [employees, setEmployees] = useState<IEmployeeLookUp[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    const canRead = hasPermission(GroupPermissionEnum.ObjectRead) && hasPermission(GroupPermissionEnum.ContractRead) && hasPermission(GroupPermissionEnum.ContractsOnEmployeesRead);
    const canEdit = hasPermission(GroupPermissionEnum.ContractsOnEmployeesEdit);

    if(!canRead){
        return <Navigate to="/" replace />
    }

    const searchContractEmployees = async () => {
        return contractEmployeesService.search({ contractId })
            .then((res) => setContractEmployees(res.items))
            .catch(console.log);
    }

    useEffect(() => {
        contractsService.searchContracts({ id: contractId })
            .then(res => res.items[0])
            .then(setContract)
            .then(searchContractEmployees)
            .catch(console.log);
    }, [contractId])

    useEffect(() => {
        employeesService.lookUp()
            .then((res) => res.items)
            .then(setEmployees)
            .catch(console.log);
    }, [])

    if(!contract) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600  }}/>
        </Container>
    )

    const asignedEmployees = contractEmployees.map(({ employeeId }) => employeeId);
    const availableEmployees = employees.filter((employee) => !asignedEmployees.includes(employee.id));

    const createContractEmployee = async (employeeId: string) => {
        return contractEmployeesService.create({ contractId: contract.id, employeeId })
            .then(searchContractEmployees)
            .catch(console.log);
    }

    const deleteContractEmployee = async (id: string) => {
        return contractEmployeesService.delete(id)
            .then(searchContractEmployees)
            .catch(console.log);
    }

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/objects"><Typography fontSize={18}>Объекты</Typography></Link>
                <Link to={`/objects/${contract.objectId}/contracts`}><Typography fontSize={18}>подразделения</Typography></Link>
                <Typography color="black" fontSize={18}>Сотрудники подразделения &quot;{contract.name}&quot;</Typography>
            </Breadcrumbs>

            {
                canEdit && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить сотрудника' }
                        </Button>
                        { showForm && <ContractEmployeesNew availableEmployees={availableEmployees} createContractEmployee={createContractEmployee} setShowForm={setShowForm} />}
                    </Box>
                )
            }


            {
                contractEmployees.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Сотрудник</TableCell>
                                    {
                                        canEdit && (
                                            <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    contractEmployees.map(contractEmployee => (
                                        <ContractEmployeesItem key={contractEmployee.id}
                                            contractEmployee={contractEmployee}
                                            employee={employees.find((item) => item.id === contractEmployee.employeeId)}
                                            canEdit={canEdit}
                                            deleleContractEmployee={deleteContractEmployee}  
                                        />
                                    ) )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default ContractEmployees;