import { Box, Button, Autocomplete, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { IEmployee } from '../../API/interfaces/employees.interfaces';
import { IPosition } from '../../API/interfaces/positions.interfaces';
import { IEmployeePositionCreate } from '../../API/interfaces/employeePositions';

interface IProps {
    employee: IEmployee;
    availablePositions: IPosition[];
    createEmployeePosition: (payload: IEmployeePositionCreate) => Promise<void>;
}

const EmployeePositionsNew: FC<IProps> = ({ employee, availablePositions, createEmployeePosition }) => {
    const [position, setPosition] = useState<IPosition | null>(null);

    const handleCreateEmployeePosition = () => position && createEmployeePosition({ employeeId: employee.id, positionId: position.id });

    return (
        <Box sx={{ marginTop: '20px' }}>
            <Autocomplete sx={{ width: 300 }}
                options={availablePositions}
                getOptionLabel={(value) => value.name}
                onChange={(e, value) => setPosition(value)}
                renderInput={(params) => <TextField {...params} label="Должность" />}
                disablePortal
            />
            <Button sx={{ marginTop: '20px' }}
                variant="outlined"
                size="large"
                onClick={handleCreateEmployeePosition}
                disabled={!position}
            >
                Добавить
            </Button>
        </Box>
    )
}

export default EmployeePositionsNew;