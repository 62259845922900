import { Breadcrumbs, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GroupedEmployeesPayments } from '../../API/interfaces/analitycs.interfaces';
import analyticsService from '../../API/services/analytics.service';

const border = '1px solid rgba(224, 224, 224, 1)';

const EmploymentPlacePayments: React.FC = () => {
    const [data, setData] = useState<GroupedEmployeesPayments[]>()

    useEffect(() => {
        analyticsService.getEmployeesPayments()
            .then(setData)
            .catch(console.log);
    }, []);

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Typography color="black" fontSize={18}>Отчет по месту трудоустройства</Typography>
            </Breadcrumbs>

            {
                data && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', overflow: 'unset' }}>
                        <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'collapse' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ border, width: '20%' }}>
                                        <Typography fontSize={18}>ФИО</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border, width: '20%' }}>
                                        <Typography fontSize={18}>Должность</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border, width: '20%' }}>
                                        <Typography fontSize={18}>Начислено</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border, width: '20%' }}>
                                        <Typography fontSize={18}>Выплачено</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border, width: '20%' }}>
                                        <Typography fontSize={18}>Итого к выплате</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.map((employmentPlace) => (
                                        <React.Fragment key={employmentPlace.id}>
                                            <TableRow>
                                                <TableCell colSpan={5} align="center" sx={{ border, width: '20%' }}>
                                                    <Typography fontSize={16}>Место трудоустройства: {employmentPlace.name}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            {
                                                employmentPlace.employees.map((employee) => (
                                                    <TableRow key={employee.id}>
                                                        <TableCell sx={{ border }}>
                                                            <Typography fontSize={16}>{employee.fullname}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ border }}>
                                                            <Typography fontSize={16}>{employee.positions.map((position) => position.name).join(', ')}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ border }}>
                                                            <Typography fontSize={16}>{employee.totalSumm}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ border }}>
                                                            <Typography fontSize={16}>{employee.totalPayments}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ border }}>
                                                            <Typography fontSize={16}>{Math.max(employee.totalSumm - employee.totalPayments, 0)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                    
                                        </React.Fragment>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default EmploymentPlacePayments;