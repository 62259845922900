import dayjs from 'dayjs';
import { ITracker } from '../API/interfaces/tracker.interfaces';
import { dateFormat } from './dateFormat';

export function trackersMapKey(tracker: Pick<ITracker, 'contractId' | 'serviceId' | 'dateAt'>){
    return tracker.contractId + '-' + tracker.serviceId + '-' + dayjs(tracker.dateAt).format(dateFormat);
}

export function trackersMapReducer(map: Map<string, ITracker>, tracker: ITracker): Map<string, ITracker> {
    map.set(trackersMapKey(tracker), tracker);
    return map;
}

export function trackersMapGetter(map: Map<string, ITracker>, tracker: Pick<ITracker, 'contractId' | 'serviceId' | 'dateAt'>) {
    return map.get(trackersMapKey(tracker))
}