import './style.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'dayjs/locale/ru';
import dayjs from 'dayjs';
dayjs.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
