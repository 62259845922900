import { IEntity, IEntitySearch } from './entity.interfaces';
import { IContractEmployee } from './contractEmployees';
import { IEmployeeGroup } from './employeeGroups.interfaces';
import { IEmploymentPlace } from './employmentPlaces.interfaces';
import { EmployeeStatusEnum } from '../enums/employeeStatus';

export interface IEmployee extends IEntity {
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  citizenship?: string;
  
  status: EmployeeStatusEnum;
  employmentType?: EmployeeEmploymentTypeEnum;
  paymentType?: EmployeePaymentTypeEnum;

  positionId?: string;
  groups: IEmployeeGroup[]
  contracts: IContractEmployee[];

  employmentPlaceId?: string;
  employmentPlace?: IEmploymentPlace;

  canAuth: boolean;
  isSuperUser: boolean;

  _count?: {
    positions: number;
    documents: number;
    groups: number;
    payments: number;
  };
}

export interface IEmployeeSearch extends IEntitySearch {
  objectId?: string;
  contractId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  citizenship?: string;
  status?: EmployeeStatusEnum;
  employmentType?: EmployeeEmploymentTypeEnum;
  paymentType?: EmployeePaymentTypeEnum;
  canAuth?: boolean;
  isSuperUser?: boolean;
  employmentPlaceId?: string;
}

export interface IEmployeeCreate {
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  citizenship?: string;
  status: EmployeeStatusEnum;
  employmentType: EmployeeEmploymentTypeEnum;
  paymentType: EmployeePaymentTypeEnum;
  canAuth?: boolean;
  employmentPlaceId: string;
}

export interface IEmployeeUpdate {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  citizenship?: string;
  email?: string;
  status?: EmployeeStatusEnum;
  employmentType?: EmployeeEmploymentTypeEnum;
  paymentType?: EmployeePaymentTypeEnum;
  canAuth?: boolean;
  employmentPlaceId?: string;
}

export interface IEmployeeLookUp {
    id: string;
    fullName: string;
}

export enum EmployeeEmploymentTypeEnum {
  someType = 'someType',
  someOtherType = 'someOtherType',
}

export enum EmployeePaymentTypeEnum {
  cash = 'cash',
  card = 'card',
}