import { Box } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const AnalyticsPage: FC = () => {
    return (
        <Box sx={{ width: '100%', maxWidth: '1024px', padding: '100px 30px 100px 300px', display: 'flex', gap: '20px'}}>
            <Link to='/analytics/employee-payments'>Зарплата сотрудникам</Link>
            <Link to='/analytics/counterparty-payments'>Расчет для заказчика</Link> 
        </Box>
    )
}

export default AnalyticsPage;