import { TableRow, TableCell, TextField, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchDate from '../SearchDate';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { IPayment, IPaymentUpdate } from '../../API/interfaces/payments.interfaces';

interface IProps {
    payment: IPayment,
    updatePayment: (id: string, payload: IPaymentUpdate) => Promise<void>,
    deletePayment: (id: string) => Promise<void>
}

const EmployeePaymentItem: FC<IProps> = ({payment, updatePayment, deletePayment}) => {
    const hasPermission = usePermissions();
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(payment.amount || 0);
    const [date, setDate] = useState<string>(payment.dateAt.toString().slice(0, 10));
    const [comment, setComment] = useState<string>(payment.comment? payment.comment : '');

    const resetValues = () => {
        setEditMode(false);
        setAmount(payment.amount)
        setDate(payment.dateAt.toString().slice(0, 10));
        setComment(payment.comment? payment.comment : '');
    }

    const isValid = amount && date ;

    const handleUpdate = () => {
        isValid && updatePayment(payment.id, {
            amount,
            dateAt: date as unknown as Date,
            comment
        })
            .then(() => setEditMode(false))
            .catch(console.log);
    }


    const onDelete = () => deletePayment(payment.id);

    if (hasPermission(GroupPermissionEnum.PaymentEdit) && isEditMode) return (
            <TableRow>
                <TableCell>
                    <TextField
                        size='small'
                        type="number"
                        variant="outlined"
                        required={true}
                        value={amount}
                        onChange={(e) => setAmount(Number(e.target.value))}
                    />
                </TableCell>
                <TableCell>
                    <SearchDate date={date} setDate={setDate} />
                </TableCell>

                <TableCell>
                    <TextField
                        size='small'
                        type="text"
                        variant="outlined"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton >
                        <CancelIcon onClick={resetValues} />
                    </IconButton>
                    <IconButton >
                        <CheckCircleIcon onClick={handleUpdate} />
                    </IconButton>
                </TableCell>
            </TableRow>
        )

    return (
        <TableRow>
            <TableCell align="center">{payment.amount}</TableCell>
            <TableCell align="center">{payment.dateAt.toString().slice(0, 10)}</TableCell>
            <TableCell align="center">{payment.comment}</TableCell>
            {hasPermission(GroupPermissionEnum.PaymentEdit) && (
                <TableCell align="center">
                    <IconButton onClick={() => setEditMode(true)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={onDelete}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            )
            }
        </TableRow>
    )
}

export default EmployeePaymentItem;