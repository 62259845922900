import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IServiceLookUp } from '../../API/interfaces/services.interfaces';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import servicesService from '../../API/services/services.service';
import usePermissions from '../../hooks/usePermissions';
import PositionServicesItem from './PositionServicesItem';
import PositionServicesNew from './PositionServicesNew';
import positionsServicesService from '../../API/services/positionsServices.service';
import { IPositionService, IPositionServiceCreate } from '../../API/interfaces/positionServices.interfaces';
import positionsService from '../../API/services/positions.service';
import { IPosition } from '../../API/interfaces/positions.interfaces';

const PositionServices: FC = () => {
    const { positionId } = useParams();
    const [services, setServices] = useState<IServiceLookUp[]>([]);
    const [position, setPosition] = useState<IPosition>();
    const [positionServices, setPositionServices] = useState<IPositionService[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    const canRead = hasPermission(GroupPermissionEnum.EmployeeRead) && hasPermission(GroupPermissionEnum.PositionsOnServicesRead);
    const canEdit = hasPermission(GroupPermissionEnum.PositionsOnServicesEdit);

    if (!canRead) {
        return <Navigate to="/" replace />
    }

    const searchPositionServices = async () => {
        return positionsServicesService.search({ positionId })
            .then((res) => setPositionServices(res.items))
            .catch(console.log)
    }

    const createPositionService = async (payload: IPositionServiceCreate) => {
        return positionsServicesService.create(payload)
            .then(searchPositionServices)
            .then(() => setShowForm(false))
            .catch(console.log)
    }

    const deletePositionService = async (id: string) => {
        return positionsServicesService.delete(id)
            .then(searchPositionServices)
            .catch(console.log)
    }

    useEffect(() => {
        servicesService.lookUp()
            .then((res) => res.items)
            .then(setServices)
            .then(console.log);
    }, [])

    useEffect(() => {
        positionsService.search({ id: positionId })
            .then((res) => res.items[0])
            .then(setPosition)
            .then(searchPositionServices)
            .catch(console.log);
    }, [positionId])

    if (!position || !positionServices) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 450 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250 }} />
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600 }} />
        </Container>
    )

    const asignedServices = positionServices.map((item) => item.serviceId);
    const availableServices = services.filter((item) => !asignedServices.includes(item.id));

    const availableTypes = availableServices.reduce<string[]>((types, { type }) => {
        if (!types.includes(type)) types.push(type);
        return types;
    }, []);

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }} >
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/positions"><Typography fontSize={18}>Должности</Typography></Link>
                <Typography color="black" fontSize={18}>Услуги должности &quot;{position?.name}&quot;</Typography>
            </Breadcrumbs>

            {
                canEdit && availableServices.length > 0 && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v => !v)}>
                            {showForm ? 'Закрыть' : 'Добавить услугу'}
                        </Button>
                        {
                            showForm && <PositionServicesNew 
                            position={position} 
                            types={availableTypes} 
                            services={availableServices} 
                            createPositionService={createPositionService} />
                        }
                    </Box>
                )
            }

            {
                positionServices.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', minHeight: 600, padding: '20px', display: 'grid', alignItems: 'flex-start' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Тип</TableCell>
                                    <TableCell align="center" sx={{ minWidth: 250 }}>Услуга</TableCell>
                                    {
                                        hasPermission(GroupPermissionEnum.PositionsOnServicesEdit) && <TableCell align="center" sx={{ minWidth: 120, width: 120 }}></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    positionServices.map((positionService) => (
                                        <PositionServicesItem
                                            positionService={positionService}
                                            services={services}
                                            canEdit={canEdit}
                                            deletePositionService={deletePositionService}
                                            key={positionService.id}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
}

export default PositionServices;