import { FC, useState } from 'react';
import { IEmploymentPlace, IEmploymentPlaceUpdate } from '../../API/interfaces/employmentPlaces.interfaces';
import { TableRow, TableCell, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface IProps {
    place: IEmploymentPlace,
    canEdit: boolean,
    onUpdate: (payload: IEmploymentPlaceUpdate) => void,
    onDelete: () => void,
}

const EmploymentPlacesItem: FC<IProps> = ({place, onUpdate, onDelete, canEdit}) => {

    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [name, setName] = useState<string>(place.name);
    
    const handleUpdate = () => {onUpdate({ name }); setEditMode(false)}

    if(//canEdit && 
        isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <TextField
                    size='small'
                    type="text"
                    variant="outlined"
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => {setEditMode(false); setName(place.name)}}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{place.name}</TableCell>
            {
                canEdit && 
                (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={onDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default EmploymentPlacesItem;