import {  IconButton, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IServiceLookUp } from '../../API/interfaces/services.interfaces';
import { IPositionService } from '../../API/interfaces/positionServices.interfaces';

interface IProps {
    positionService: IPositionService,
    services: IServiceLookUp[],
    canEdit: boolean,
    deletePositionService: (id: string) => void
}

const PositionServicesItem: FC<IProps> = ({ services, positionService, canEdit, deletePositionService }) => {
    
    const service = services.find((item) => item.id === positionService.serviceId);

    const handleDeletePositionService = () => deletePositionService(positionService.id);
 
    return (
        <TableRow>
            <TableCell align="center">{service?.type}</TableCell>
            <TableCell align="center">{service?.name}</TableCell>
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={handleDeletePositionService}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default PositionServicesItem;