import { Box, Breadcrumbs, Button, Container, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { IContract } from '../../API/interfaces/contracts.interfaces';
import { IContractServiceCreate, IContractServiceUpdate } from '../../API/interfaces/contractServices.interfaces';
import { IService } from '../../API/interfaces/services.interfaces';
import { IServiceType } from '../../API/interfaces/serviceTypes.interfaces';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import contractsService from '../../API/services/contracts.service';
import contractServicesService from '../../API/services/contractServices.service';
import servicesService from '../../API/services/services.service';
import usePermissions from '../../hooks/usePermissions';
import ContractServicesItem from './ContractServicesItem';
import ContractServicesNew from './ContractServicesNew';

const ContractServices: FC = () => {
    const { contractId } = useParams();
    const [contract, setContract] = useState<IContract>();
    const [services, setServices] = useState<IService[]>([]);
    const [showForm, setShowForm] = useState(false);

    const hasPermission = usePermissions();
    const canRead = hasPermission(GroupPermissionEnum.ObjectRead) && hasPermission(GroupPermissionEnum.ContractRead) && hasPermission(GroupPermissionEnum.ServiceRead);
    const canEdit = hasPermission(GroupPermissionEnum.ContractsOnServicesEdit);
    
    if(!canRead){
        return <Navigate to="/" replace />
    }

    const searchContract = async () => {
        return contractsService.searchContracts({ id: contractId })
            .then(res => res.items[0])
            .then(setContract)
            .catch(console.log) 
    }

    const createContractService = async (payload: IContractServiceCreate) => {
        return contractServicesService.create(payload)
            .then(searchContract)
            .catch(console.log);
    }

    const updateContractService = (id: string, payload: Omit<IContractServiceUpdate, 'contractId'>) => {
        contractServicesService.update(id, payload)
            .then(searchContract)
            .catch(console.log);
    }

    const deleteContractService = (id: string) => {
        contractServicesService.delete(id)
            .then(searchContract)
            .catch(console.log);
    }

    useEffect(() => { searchContract() }, [contractId]);

    useEffect(() => {
        servicesService.searchServices()
            .then(res => res.items)
            .then(setServices)
            .then(console.log)
    }, []);

    if(!contract) return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Skeleton variant="text" sx={{ fontSize: 28, maxWidth: 650  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', fontSize: 36, maxWidth: 250  }}/>
            <Skeleton variant="rounded" sx={{ marginTop: '40px', minHeight: 600  }}/>
        </Container>
    )

    const asignedServices = contract.services.map(({ service }) => service.id);
    const availableServices = services.filter(service => !asignedServices.includes(service.id));
    const availableTypes = availableServices.reduce<IServiceType[]>((types, { serviceType }) => {
        if(serviceType && !types.find(type => type.id === serviceType.id)) {
            types.push(serviceType);
        }
        return types;
    }, []);

    return (
        <Container maxWidth={false} sx={{ padding: '60px 20px' }}>
            <Breadcrumbs>
                <Link to="/"><Typography fontSize={18}>Главная</Typography></Link>
                <Link to="/objects"><Typography fontSize={18}>Объекты</Typography></Link>
                <Link to={`/objects/${contract.objectId}/contracts`}><Typography fontSize={18}>подразделения</Typography></Link>
                <Typography color="black" fontSize={18}>Услуги подразделения &quot;{contract.name}&quot;</Typography>
            </Breadcrumbs>
            
            {
                canEdit && (
                    <Box sx={{ marginTop: '40px' }}>
                        <Button variant="contained" size="large" onClick={() => setShowForm(v=>!v)}>
                            { showForm ? 'Закрыть' : 'Добавить услугу' }
                        </Button>
                        { showForm && <ContractServicesNew contract={contract} availableServices={availableServices} createContractService={createContractService} /> }
                    </Box>
                )
            }
            
            {
                contract.services.length > 0 && (
                    <TableContainer component={Paper} elevation={10} sx={{ marginTop: '40px', padding: '20px', minHeight: 600 , display: 'grid', alignItems: 'flex-start'}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{minWidth: 250 }}>Тип</TableCell>
                                    <TableCell align="center" sx={{minWidth: 250 }}>Услуга</TableCell>
                                    {
                                        !contract.costForCounterpartyFixed && <TableCell align="center" sx={{minWidth: 250 }}>Цена для заказчика</TableCell>
                                    }
                                    <TableCell align="center" sx={{minWidth: 250 }}>Цена для персонала</TableCell>
                                    {
                                        canEdit && (
                                            <TableCell align="center" width="120px"></TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    contract.services.map(contractsService => (
                                        <ContractServicesItem
                                            canEdit={canEdit}
                                            onDelete={() => deleteContractService(contractsService.id)}
                                            onUpdate={payload => updateContractService(contractsService.id, payload)}
                                            types={availableTypes}
                                            services={availableServices} 
                                            contractService={contractsService} 
                                            contract={contract}
                                            key={contractsService.serviceId}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Container>
    )
    
    
}

export default ContractServices;