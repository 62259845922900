import { Button, Checkbox, IconButton, MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IEmployee, IEmployeeUpdate } from '../../API/interfaces/employees.interfaces';
import { Link } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { IEmploymentPlace } from '../../API/interfaces/employmentPlaces.interfaces';
import { EmployeeStatusEnum } from '../../API/enums/employeeStatus';

interface IProps {
    employee: IEmployee,
    updateEmployee: (id: string, payload: IEmployeeUpdate) => Promise<void>,
    deleteEmployee: (id: string) => Promise<void>,
    places: IEmploymentPlace[]
}

const ServicesItem: FC<IProps> = ({ employee, updateEmployee, deleteEmployee, places }) => {
    const hasPermission = usePermissions();

    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>(employee.lastName);
    const [firstName, setFirstName] = useState<string>(employee.firstName);
    const [middleName, setMiddleName] = useState<string>(employee.middleName || '');
    const [citizenship, setСitizenship] = useState<string>(employee.citizenship || '');
    const [email, setEmail] = useState<string>(employee.email || '');
    const [phoneNumber, setPhoneNumber] = useState<string>(employee.phoneNumber || '');
    const [canAuth, setAuthAbility] = useState<boolean>(employee.canAuth || false);
    const [status, setStatus] = useState<EmployeeStatusEnum>(employee.status);

    const [placeId, setPlaceId] = useState<string>();

    const [error, setError] = useState('');

    const resetValues = () => {
        setEditMode(false);
        setLastName(employee.lastName);
        setFirstName(employee.firstName);
        setMiddleName(employee.middleName || '');
        setСitizenship(employee.citizenship || '');
        setEmail(employee.email || '');
        setPhoneNumber(employee.phoneNumber || '');
        setAuthAbility(employee.canAuth || false);
        setStatus(employee.status);
    }

    const employeeContractsString = useMemo(() => {
        return employee.contracts.map((employeeContract) => `${employeeContract.contract?.name} ${employeeContract.contract?.object?.name}`).join(', ');
    }, [employee]);

    const isValid = firstName && lastName && phoneNumber && status && placeId;

    const handleUpdate = () => {
        isValid && updateEmployee(employee.id, {
            firstName,
            middleName,
            lastName,
            citizenship,
            email,
            phoneNumber,
            status,
            employmentPlaceId: placeId,
            canAuth
        })
            .then(() => setEditMode(false))
            .catch((err) => err.message && setError(err.message));
    }

    const handleDelete = () => deleteEmployee(employee.id);

    if (hasPermission(GroupPermissionEnum.EmployeeEdit) && isEditMode) return (
        <TableRow>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <TextField type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    error={Boolean(error)}
                    required={true}
                />
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <TextField type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={Boolean(error)}
                    required={true}
                />
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <TextField type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                    error={Boolean(error)}
                />
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <TextField type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required={true}
                />
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <TextField type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <TextField type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={citizenship}
                    onChange={(e) => setСitizenship(e.target.value)}
                />
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <Select required size="small" fullWidth value={status} onChange={e => setStatus(e.target.value as EmployeeStatusEnum)} >
                    {
                        Object.values(EmployeeStatusEnum).map((status) => (
                            <MenuItem value={status} key={status}>
                                {status}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center" sx={{ minWidth: '200px' }}>
                <Select required size="small" fullWidth value={placeId} onChange={e => setPlaceId(e.target.value)} >
                    {
                        places && places.map((place) => (
                            <MenuItem value={place.id} key={place.id}>
                                {place.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </TableCell>
            <TableCell align="center">
                <Checkbox checked={canAuth} onChange={() => setAuthAbility(!canAuth)} />
            </TableCell>
            <TableCell align="center">{ employeeContractsString }</TableCell>
            {
                hasPermission(GroupPermissionEnum.EmployeesOnPositionsRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/positions`}>
                            <Button variant="outlined">{employee._count?.positions}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/groups`}>
                            <Button variant="outlined">{employee.groups.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                // TEMP hasPermission(GroupPermissionEnum.PositionsOnServicesRead) && (
                //     <TableCell align="center">
                //         <Link to={`/employees/${employee.id}/services`}>
                //             <Button variant="outlined">{employee.services?.length || 0}</Button>
                //         </Link>
                //     </TableCell>
                // )
            }
            {
                hasPermission(GroupPermissionEnum.DocumentRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/documents`}>
                            <Button variant="outlined">{employee._count?.documents}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
               hasPermission(GroupPermissionEnum.PaymentRead) && ( <TableCell align="center">
                    <Link to={`/employees/${employee.id}/payments`}>
                        <Button variant="outlined">{employee._count?.payments ? employee._count?.payments : 0}</Button>
                    </Link>
                </TableCell>
                )
            }
            <TableCell align="center">
                <IconButton onClick={resetValues}>
                    <CancelIcon />
                </IconButton>
                <IconButton onClick={handleUpdate} disabled={!isValid}>
                    <CheckCircleIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    return (
        <TableRow>
            <TableCell align="center">{employee.lastName}</TableCell>
            <TableCell align="center">{employee.firstName}</TableCell>
            <TableCell align="center">{employee.middleName}</TableCell>
            <TableCell align="center">{employee.phoneNumber}</TableCell>
            <TableCell align="center">{employee.email}</TableCell>
            <TableCell align="center">{employee.citizenship}</TableCell>
            <TableCell align="center">{employee.status}</TableCell>
            <TableCell align="center">{employee.employmentPlace ? employee.employmentPlace.name : ''}</TableCell>
            <TableCell align="center">
                <Checkbox checked={employee.canAuth} disabled={true} />
            </TableCell>
            <TableCell align="center">{ employeeContractsString }</TableCell>
            {
                hasPermission(GroupPermissionEnum.EmployeesOnPositionsRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/positions`}>
                            <Button variant="outlined">{employee._count?.positions}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/groups`}>
                            <Button variant="outlined">{employee.groups.length || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                // TEMP hasPermission(GroupPermissionEnum.PositionsOnServicesRead) && (
                //     <TableCell align="center">
                //         <Link to={`/employees/${employee.id}/services`}>
                //             <Button variant="outlined">{employee.services?.length || 0}</Button>
                //         </Link>
                //     </TableCell>
                // )
            }
            {
                hasPermission(GroupPermissionEnum.DocumentRead) && (
                    <TableCell align="center">
                        <Link to={`/employees/${employee.id}/documents`}>
                            <Button variant="outlined">{employee._count?.documents}</Button>
                        </Link>
                    </TableCell>
                )
            }

            <TableCell align="center">
                <Link to={`/employees/${employee.id}/payments`}>
                    <Button variant="outlined">{employee._count?.payments ? employee._count?.payments : 0}</Button>
                </Link>
            </TableCell>

            {
                hasPermission(GroupPermissionEnum.EmployeeEdit) && (
                    <TableCell align="center" >
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default ServicesItem;