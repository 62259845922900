import { useState } from 'react';
import { TablePagination } from '@mui/material';

interface IPaginationOptions {
    limit: number,
    offset: number,
    count: number
}

const defaultOptions: IPaginationOptions = {
    limit: 50,
    offset: 0,
    count: 0  
}

const usePagination = ( options: IPaginationOptions = defaultOptions ) => {
    const [limit, setLimit] = useState(options.limit);
    const [offset, setOffset] = useState(options.offset);
    const [count, setCount] = useState(options.count);

    const Pagination = () => (
        <TablePagination sx={{ marginTop: 'auto' }}
            count={count}
            page={offset / limit}
            onPageChange={(_, page) => setOffset(page * limit)}
            rowsPerPage={limit}
            onRowsPerPageChange={(e) => setLimit(parseInt(e.target.value))}
            component="div"
        />
    )

    return {
        limit,
        setLimit,
        offset,
        setOffset,
        count,
        setCount,
        Pagination
    }
}

export default usePagination;