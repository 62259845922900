import apiClient, { ApiClient } from '../apiClient';
import { SearchResult } from '../interfaces/entity.interfaces';
import { IServiceTypeCreate, IServiceTypeSearch, IServiceType, IServiceTypeUpdate } from '../interfaces/serviceTypes.interfaces';

export class ServiceTypesService {
    constructor(private api: ApiClient){}

    public async search(search?: IServiceTypeSearch){
        return this.api.get<SearchResult<IServiceType>>('service-types', search);
    }

    public async create(payload: IServiceTypeCreate){
        return this.api.post<IServiceType>('service-types', payload);
    }

    public async update(id: string, payload: IServiceTypeUpdate){
        return this.api.patch<IServiceType>('service-types/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('service-types/' + id);
    }
}

export default new ServiceTypesService(apiClient);