import { FC, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import objectsService from '../API/services/objects.service';
import { IEntityLookUp } from '../API/interfaces/entity.interfaces';

interface ISearchObjectProps {
    objectId: string,
    setObjectId: (value: string) => void
}

const SearchObject: FC<ISearchObjectProps> = ({ objectId, setObjectId }) => {
    const [objects, setObjects] = useState<IEntityLookUp[]>([]);
    const object = (objectId && objects.find((item) => item.id == objectId)) || null;

    useEffect(() => {
            objectsService.lookUp()
                .then(res => res.items)
                .then(setObjects)
                .catch(console.log);
    }, [])

    return (
        <Autocomplete sx={{ width: 300 }}
            options={objects}
            getOptionLabel={(value) => value.name}
            value={object}
            onChange={(e, value) => setObjectId(value?.id || '')}
            renderInput={(params) => <TextField {...params} label="Объект" />}
            disablePortal
        />
    )
}

export default SearchObject;