import { Button, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { FC, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IPosition, IPositionUpdate } from '../../API/interfaces/positions.interfaces';
import usePermissions from '../../hooks/usePermissions';
import { GroupPermissionEnum } from '../../API/enums/groupPermission';
import { Link } from 'react-router-dom';


interface IProps {
    position: IPosition,
    canEdit: boolean,
    onUpdate: (id: string, payload: IPositionUpdate) => Promise<void>,
    onDelete: (id: string) => Promise<void>,
}

const PositionsItem: FC<IProps> = ({ position, canEdit, onUpdate, onDelete }) => {
    const hasPermission = usePermissions();
    
    const [isEditMode, setEditMode] = useState(false);
    const [name, setName] = useState(position.name);
    const [error, setError] = useState('');

    const handleUpdate = () => {
        setError('');
        onUpdate(position.id, { name })
            .then(() => setEditMode(false))
            .catch((err) => err.message && setError(err.message));
    } 

    const handleDelete = () => onDelete(position.id);

    if(isEditMode) return (
        <TableRow>
            <TableCell align="center">
                <TextField type="text"
                    size='small'
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={Boolean(error)}
                    required={true}
                />
            </TableCell>
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && (
                    <TableCell align="center">
                        <Link to={`/positions/${position.id}/services`}>
                            <Button variant="outlined">{position._count?.services || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            <TableCell align="center">
                <IconButton onClick={() => {setEditMode(false); setName(position.name)}}>
                    <CancelIcon/>
                </IconButton>
                <IconButton onClick={handleUpdate}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
    
    return (
        <TableRow>
            <TableCell align="center">{position.name}</TableCell>
            {
                hasPermission(GroupPermissionEnum.EmployeesOnGroupsRead) && (
                    <TableCell align="center">
                        <Link to={`/positions/${position.id}/services`}>
                            <Button variant="outlined">{position._count?.services || 0}</Button>
                        </Link>
                    </TableCell>
                )
            }
            {
                canEdit && (
                    <TableCell align="center">
                        <IconButton onClick={() => setEditMode(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    </TableCell>
                )
            }
        </TableRow>
    )
}

export default PositionsItem;