import apiClient, { ApiClient } from '../apiClient';
import { IEmploymentPlace, IEmploymentPlaceCreate, IEmploymentPlaceSearch, IEmploymentPlaceUpdate } from '../interfaces/employmentPlaces.interfaces';
import { SearchResult } from '../interfaces/entity.interfaces';

export class EmploymentPlacesService {
    constructor(private api: ApiClient){}

    public async search(search?: IEmploymentPlaceSearch){
        return this.api.get<SearchResult<IEmploymentPlace>>('employment-places', search);
    }

    public async create(payload: IEmploymentPlaceCreate){
        return this.api.post<IEmploymentPlace>('employment-places', payload);
    }

    public async update(id: string, payload: IEmploymentPlaceUpdate){
        return this.api.patch<IEmploymentPlace>('employment-places/' + id, payload);
    }

    public async delete(id: string){
        return this.api.delete<void>('employment-places/' + id);
    }
}

export default new EmploymentPlacesService(apiClient);